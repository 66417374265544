import React from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { FormData } from '../EventForm';

interface BasicInfoStepProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  errors: Partial<Record<keyof FormData, string>>;
  autocomplete: google.maps.places.Autocomplete | null;
  setAutocomplete: (autocomplete: google.maps.places.Autocomplete | null) => void;
  onPlaceSelected: () => void;
  isLoaded: boolean;
}


const BasicInfoStep: React.FC<BasicInfoStepProps> = ({
  formData,
  setFormData,
  errors,
  autocomplete,
  setAutocomplete,
  onPlaceSelected,
  isLoaded
}) => {
  const handleWeekDayToggle = (dayId: string) => {
    setFormData(prev => ({
      ...prev,
      weekDays: prev.weekDays?.includes(dayId)
        ? prev.weekDays.filter(id => id !== dayId)
        : [...(prev.weekDays || []), dayId]
    }));
  };

  const weekDays = [
    { id: 'monday', label: 'Lun' },
    { id: 'tuesday', label: 'Mar' },
    { id: 'wednesday', label: 'Mer' },
    { id: 'thursday', label: 'Jeu' },
    { id: 'friday', label: 'Ven' },
    { id: 'saturday', label: 'Sam' },
    { id: 'sunday', label: 'Dim' }
  ];

  return (
    <div className="form-step">
      <div className="form-group">
        <label htmlFor="title">Titre de l'événement *</label>
        <input
          type="text"
          id="title"
          value={formData.title}
          onChange={e => setFormData(prev => ({ ...prev, title: e.target.value }))}
          className={errors.title ? 'error' : ''}
        />
        {errors.title && <span className="error-message">{errors.title}</span>}
      </div>

      <div className="form-group">
        <label htmlFor="location">Localisation</label>
        {isLoaded ? (
          <Autocomplete
            onLoad={autocomplete => setAutocomplete(autocomplete)}
            onPlaceChanged={onPlaceSelected}
          >
            <input
              type="text"
              id="location"
              value={formData.location}
              onChange={e => setFormData(prev => ({ ...prev, location: e.target.value }))}
              placeholder="Rechercher un lieu..."
              className={`location-input ${errors.location ? 'error' : ''}`}
            />
          </Autocomplete>
        ) : (
          <input
            type="text"
            disabled
            placeholder="Chargement..."
            className="location-input"
          />
        )}
        {errors.location && <span className="error-message">{errors.location}</span>}
      </div>

      <div className="form-group">
        <div className="event-type-toggle">
          <button
            type="button"
            className={`toggle-button ${!formData.isRecurring ? 'active' : ''}`}
            onClick={() => setFormData(prev => ({ ...prev, isRecurring: false }))}
          >
            One Shot
          </button>
          <button
            type="button"
            className={`toggle-button ${formData.isRecurring ? 'active' : ''}`}
            onClick={() => setFormData(prev => ({ ...prev, isRecurring: true }))}
          >
            Récurrent
          </button>
        </div>

        {!formData.isRecurring ? (
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="date">Date</label>
              <input
                type="date"
                id="date"
                value={formData.date}
                onChange={e => setFormData(prev => ({ ...prev, date: e.target.value }))}
              />
            </div>
            <div className="form-group">
              <label htmlFor="time">Heure</label>
              <input
                type="time"
                id="time"
                value={formData.time}
                onChange={e => setFormData(prev => ({ ...prev, time: e.target.value }))}
              />
            </div>
          </div>
        ) : (
          <div className="recurrence-fields">
            <div className="form-row">
              <div className="form-group">
                <label>Première date</label>
                <input
                  type="date"
                  value={formData.startDate}
                  onChange={e => setFormData(prev => ({ ...prev, startDate: e.target.value }))}
                />
              </div>
              <div className="form-group">
                <label>Heure</label>
                <input
                  type="time"
                  value={formData.time}
                  onChange={e => setFormData(prev => ({ ...prev, time: e.target.value }))}
                />
              </div>
            </div>

            <div className="form-group">
              <label>Fréquence</label>
              <div className="event-type-toggle">
                <button
                  type="button"
                  className={`toggle-button ${formData.frequency === 'daily' ? 'active' : ''}`}
                  onClick={() => setFormData(prev => ({ ...prev, frequency: 'daily' }))}
                >
                  Tous les jours
                </button>
                <button
                  type="button"
                  className={`toggle-button ${formData.frequency === 'weekly' ? 'active' : ''}`}
                  onClick={() => setFormData(prev => ({ ...prev, frequency: 'weekly' }))}
                >
                  Toutes les semaines
                </button>
              </div>
            </div>

            {formData.frequency === 'weekly' && (
              <div className="form-group">
                <label>Jours de la semaine</label>
                <div className="weekdays-selector">
                  {weekDays.map(day => (
                    <button
                      key={day.id}
                      type="button"
                      className={`day-button ${formData.weekDays?.includes(day.id) ? 'active' : ''}`}
                      onClick={() => handleWeekDayToggle(day.id)}
                    >
                      {day.label}
                    </button>
                  ))}
                </div>
              </div>
            )}

            <div className="form-group">
              <label>Se termine</label>
              <div className="end-type-toggle">
                <div className="end-option">
                  <label>
                    <input
                      type="radio"
                      name="endType"
                      value="date"
                      checked={formData.endType === 'date'}
                      onChange={e => setFormData(prev => ({ ...prev, endType: 'date' }))}
                    />
                    À une date spécifique
                  </label>
                  {formData.endType === 'date' && (
                    <input
                      type="date"
                      value={formData.endDate}
                      min={formData.startDate}
                      onChange={e => setFormData(prev => ({ ...prev, endDate: e.target.value }))}
                      className="end-date-input"
                    />
                  )}
                </div>

                <div className="end-option">
                  <label>
                    <input
                      type="radio"
                      name="endType"
                      value="occurrences"
                      checked={formData.endType === 'occurrences'}
                      onChange={e => setFormData(prev => ({ ...prev, endType: 'occurrences' }))}
                    />
                    Après un nombre d'occurrences
                  </label>
                  {formData.endType === 'occurrences' && (
                    <input
                      type="number"
                      min="1"
                      max="52"
                      value={formData.occurrences}
                      onChange={e => setFormData(prev => ({ ...prev, occurrences: Number(e.target.value) }))}
                      placeholder="Nombre d'occurrences"
                      className="end-occurrences-input"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BasicInfoStep;