import React, { useEffect, useState, useRef } from 'react';
import './EventModal.css';
import { Event } from '../../types/event.types';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { useOrganizer } from '../../contexts/OrganizerContext'; 
import { useSpring, animated } from 'react-spring';


const defaultIcon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
  });
  L.Marker.prototype.options.icon = defaultIcon;


  
interface EventModalProps {
  event: Event;
  isOpen: boolean;
  onClose: () => void;
  isFavorite: boolean;
  onToggleFavorite: () => void; 
  isMapView: boolean;
  className?: string;   
}




const formatDateTime = (datetime: Date | undefined) => {
  if (!datetime) return { date: 'Date à définir', time: 'Heure à définir' };
  
  const date = new Intl.DateTimeFormat('fr-FR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  }).format(datetime);

  const time = new Intl.DateTimeFormat('fr-FR', {
    hour: '2-digit',
    minute: '2-digit'
  }).format(datetime);

  return { date, time };
};

const EventModal: React.FC<EventModalProps> = ({ event, isOpen, onClose, isFavorite, onToggleFavorite, isMapView = false, className = '' }) => {
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const [showShareNotification, setShowShareNotification] = useState(false);
  const { getPublicProfile } = useOrganizer(); 
  const [organizerInfo, setOrganizerInfo] = useState<ReturnType<typeof getPublicProfile> extends Promise<infer T> ? T : never>(null);
  const [isClosing, setIsClosing] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);
  
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    setIsAtTop(target.scrollTop <= 0);
  };

  // Gestionnaire de touch pour la fermeture
  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!isAtTop) return;

    const touch = e.touches[0];
    const target = scrollRef.current;
    
    if (!target) return;

    if (target.scrollTop <= 0) {
      const deltaY = touch.clientY - (target.dataset.touchStartY ? parseInt(target.dataset.touchStartY) : touch.clientY);
      
      if (deltaY > 0) {  // Si on tire vers le bas
        // On se contente de suivre le doigt
        api.start({ y: deltaY, immediate: true });
      }
    }
  };
  
  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (scrollRef.current) {
      const touch = e.changedTouches[0];  // On récupère le dernier point de contact
      const startY = scrollRef.current.dataset.touchStartY ? 
        parseInt(scrollRef.current.dataset.touchStartY) : 0;
      const currentY = touch.clientY - startY;
  
      if (currentY > 100) {  // Si on relâche au-delà du seuil
        // Animation de fermeture
        api.start({
          y: window.innerHeight,
          immediate: false,
          onRest: () => {
            onClose();
          }
        });
      } else {
        // Retour en position initiale
        api.start({ 
          y: 0, 
          immediate: false 
        });
      }
      
      delete scrollRef.current.dataset.touchStartY;
    }
  };

  const handleClose = () => {
    if (isMapView) {
      setIsClosing(true);
      setTimeout(() => {
        onClose();
        setIsClosing(false);
      }, 300); // Durée de l'animation
    } else {
      onClose();
    }
  }; 

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touch = e.touches[0];
    if (scrollRef.current) {
      scrollRef.current.dataset.touchStartY = touch.clientY.toString();
    }
  };





  const [{ y }, api] = useSpring(() => ({
    y: isMapView ? window.innerHeight : 0,
    config: {
      tension: 200,
      friction: 20,
      duration: 200
    },
    onRest: () => {
      if (isClosing) {
        onClose();
      }
    }
  }));  

  useEffect(() => {
    if (isOpen) {
      api.start({
        y: 0,
        immediate: false
      });
    }
  }, [isOpen, api]);


  // Détection du mobile
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  

  useEffect(() => {
    if (isOpen && isMobile && isMapView) {
      document.body.style.overscrollBehavior = 'none';
      return () => {
        document.body.style.overscrollBehavior = 'auto';
      };
    }
  }, [isOpen, isMobile, isMapView]);

  useEffect(() => {
    const loadOrganizerInfo = async () => {
      if (event.organizerId) {
        try {
          const organizer = await getPublicProfile(event.organizerId);
          setOrganizerInfo(organizer);
        } catch (error) {
          console.error('Erreur lors du chargement des informations de l\'organisateur:', error);
        }
      }
    };
    loadOrganizerInfo();
  }, [event.organizerId, getPublicProfile]);

  useEffect(() => {
    if (isOpen && !isMapView) { 
      // Sauvegarder la position de défilement actuelle
      const scrollY = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
    } else {
      // Restaurer la position de défilement
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
    };
  }, [isOpen, isMapView]);

  React.useEffect(() => {
    if (isOpen) {
      // Ajouter un nouvel état dans l'historique
      window.history.pushState(null, '', window.location.pathname);

      // Gérer le bouton retour
      const handlePopState = () => {
        onClose();
      };

      window.addEventListener('popstate', handlePopState);

      // Nettoyer l'événement quand la modal se ferme
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [isOpen, onClose]);

  const handleShare = async () => {
    try {
      const { date } = formatDateTime(event.datetime);
      
      if (navigator.share && typeof navigator.share === 'function') {
        try {
          await navigator.share({
            title: event.title,
            text: `Découvrez ${event.title} à ${event.location} le ${date}`,
            url: window.location.href
          });
          return;
        } catch (shareError) {
          console.log('Erreur de partage natif, utilisation du fallback');
        }
      }

      if (navigator.clipboard) {
        await navigator.clipboard.writeText(
          `${event.title} à ${event.location} le ${date}\n${window.location.href}`
        );
        setShowShareNotification(true);
        setTimeout(() => setShowShareNotification(false), 1000);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = `${event.title} à ${event.location} le ${date}\n${window.location.href}`;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          setShowShareNotification(true);
          setTimeout(() => setShowShareNotification(false), 1000);
        } catch (err) {
          alert('Impossible de partager sur cet appareil');
        }
        document.body.removeChild(textArea);
      }
    } catch (error) {
      console.error('Erreur lors du partage:', error);
      alert('Impossible de partager sur cet appareil');
    }
  };

  const defaultCoordinates: [number, number] = [5.962751602225611, 80.70675733337825];
  const coordinates: [number, number] = [
    event.latitude ?? defaultCoordinates[0],
    event.longitude ?? defaultCoordinates[1]
  ];  

  const handleCopyContact = async () => {
    if (!organizerInfo?.organizerContact) return;
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(organizerInfo.organizerContact); 
        setShowCopyNotification(true);
        setTimeout(() => setShowCopyNotification(false), 1000);
      } else {
        // Fallback pour les appareils qui ne supportent pas clipboard API
        const textArea = document.createElement('textarea');
        textArea.value = organizerInfo.organizerContact;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          setShowCopyNotification(true);
          setTimeout(() => setShowCopyNotification(false), 1000);
        } catch (err) {
          alert('Impossible de copier le contact');
        }
        document.body.removeChild(textArea);
      }
    } catch (error) {
      console.error('Erreur lors de la copie:', error);
      alert('Impossible de copier le contact');
    }
  };

  const formatDate = (date: Date | undefined) => {
    if (!date) return 'Date à définir';
    return new Intl.DateTimeFormat('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }).format(date);
  };

  

  if (!isOpen) return null;
 

  return (
    <div 
      className={`modal-overlay ${isMapView ? 'map-view' : 'list-view'}`} 
      onClick={handleClose}
      style={{ 
        zIndex: 9998
      }}
    >
    <animated.div 
  className={`modal-content ${isMapView ? 'modal-map-view' : 'modal-list-view'}`}
  onClick={e => e.stopPropagation()}
  style={{ 
    transform: y.to(py => `translateY(${py}px)`),
  }}
>
  {isMobile && isMapView && (
    <div className="drag-indicator">
      <div className="drag-handle" />
    </div>
  )}
  
  <div 
    ref={scrollRef}
    className="modal-scrollable-content"
    onScroll={handleScroll}
    onTouchStart={handleTouchStart}
    onTouchMove={handleTouchMove}
    onTouchEnd={handleTouchEnd}
  >
 
        {/* Navigation */}
        <div className="modal-nav">
          <button className="modal-back" onClick={handleClose}>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>
            </svg>
          </button>
        </div>

     
        {/* Contenu principal */}
        <div className={`modal-image-container ${event.image ? 'has-image' : ''}`}>
  {event.image && <img src={event.image} alt={event.title} className="modal-image" />}
</div>

        <div className="modal-tags-container">
          <span className="modal-tag">{event.tags[0]}</span>
          <span className="modal-price">${event.price}</span>
        </div>

        <h1 className="modal-title">{event.title}</h1>
        <p className="modal-description">{event.description}</p>

        {/* Informations détaillées */}
        <div className="modal-details">
          <div className="detail-item">
            <svg className="detail-icon" viewBox="0 0 24 24">
              <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"/>
            </svg>
            <div>
            <div className="detail-label">Date & Time</div>
        <div className="detail-value">{formatDateTime(event.datetime).date}</div>
        <div className="detail-time">{formatDateTime(event.datetime).time}</div>
      </div>
          </div>

          <div className="detail-item">
            <svg className="detail-icon" viewBox="0 0 24 24">
              <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"/>
              <circle cx="12" cy="9" r="2.5"/>
            </svg>
            <div>
              <div className="detail-label">Location</div>
              <div className="detail-value">{event.location}</div>
              <div className="detail-address">{event.location}</div>
            </div>
          </div>

          <div className="detail-item">
            <svg className="detail-icon" viewBox="0 0 24 24">
              <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"/>
            </svg>
            <div>
              <div className="detail-label">Attendance</div>
              <div className="detail-value">{event.views} interested</div>
            </div>
          </div>
        </div>

        {/* Organisateur */}
        <div className="modal-organizer">
    <img 
      src={organizerInfo?.organizerImage} 
      alt={organizerInfo?.organizer} 
      className="organizer-image" 
    />
    <div className="organizer-info">
      <div className="organizer-label">Organisé par</div>
      <div className="organizer-name">{organizerInfo?.organizer}</div>
      <div 
        className="organizer-contact"
        onClick={handleCopyContact}
      >
        📞 {organizerInfo?.organizerContact}
        {showCopyNotification && (
          <div className="copy-notification">
            Numéro copié ! ✨
          </div>
        )}
       </div>
  </div>
</div>

          {/* Carte */}
          {!isMapView && (
        <div className="modal-map-container">
          <MapContainer 
            center={coordinates}
            zoom={13} 
            scrollWheelZoom={false}
            className="modal-map"
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={coordinates}>
              <Popup>
                {event.title} <br /> {event.location || 'Lieu à définir'}
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      )}
</div>

        {/* Boutons d'action */}
<div className="modal-actions-footer">
      <button 
        className={`action-button ${isFavorite ? 'active' : ''}`}
        onClick={onToggleFavorite}
      >
        <svg 
          width="20" 
          height="20" 
          viewBox="0 0 24 24"
          fill={isFavorite ? "currentColor" : "none"}
          stroke="currentColor"
          strokeWidth="2"
        >
          <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
        </svg>
        {isFavorite ? 'Saved' : 'Save'}
      </button>
      <button className="action-button" onClick={handleShare}>
  <svg width="20" height="20" viewBox="0 0 24 24">
    <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92z"/>
  </svg>
  Share
  {showShareNotification && (
          <div className="share-notification">
            Lien copié ! ✨
            </div>
        )}
</button>
</div>
      </animated.div>
    </div>
    
  );
};

export default EventModal;