import { db } from './config';
import { 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc,
  Timestamp,
  serverTimestamp,
  onSnapshot 
} from 'firebase/firestore';
import { OrganizerProfile, OrganizerProfileFormData } from '../types/organizer.types';

const COLLECTION_NAME = 'organizers'; 

class OrganizerService {
  // Pour les visiteurs (informations publiques)
  async getPublicProfile(organizerId: string) {
    try {
      const organizerRef = doc(db, COLLECTION_NAME, organizerId);
      const organizerDoc = await getDoc(organizerRef);
      
      if (organizerDoc.exists()) {
        const data = organizerDoc.data();
        return {
          organizer: data.organizer || '',
          organizerImage: data.organizerImage || '',
          location: data.location || '',
          organizerContact: data.organizerContact || '' // Ajout du contact
        };
      }
      return null;
    } catch (error) {
      console.error('Erreur lors de la récupération du profil public:', error);
      throw error;
    }
  }

  // Pour l'organisateur connecté (profil complet)
  async getProfile(userId: string): Promise<OrganizerProfile | null> {
    try {
      const organizerRef = doc(db, COLLECTION_NAME, userId);
      const organizerDoc = await getDoc(organizerRef);

      if (!organizerDoc.exists()) {
        return null;
      }

      const data = organizerDoc.data();
      
      return {
        id: organizerDoc.id,
        organizer: data.organizer || '',
        subtitle: data.subtitle || '',
        organizerImage: data.organizerImage || '',
        organizerContact: data.organizerContact || '',
        location: data.location || '',
        latitude: data.latitude || 0,
        longitude: data.longitude || 0,
        stats: data.stats || {
          totalEvents: 0,
          totalViews: 0,
          activeEvents: 0
        },
        createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(),
        updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : new Date()
      };
    } catch (error) {
      console.error('Erreur lors de la récupération du profil:', error);
      throw error;
    }
  }

  async saveProfile(userId: string, profileData: OrganizerProfileFormData): Promise<void> {
    const organizerRef = doc(db, COLLECTION_NAME, userId);
    const organizerDoc = await getDoc(organizerRef);
  
    const profileToSave = {
      organizer: profileData.organizer || '',
      subtitle: profileData.subtitle || '',
      organizerImage: profileData.organizerImage || '',
      organizerContact: profileData.organizerContact || '',
      location: profileData.location || '',
      latitude: profileData.latitude || 0,
      longitude: profileData.longitude || 0,
      stats: {
        totalEvents: 0,
        totalViews: 0,
        activeEvents: 0
      }
    };
  
    if (!organizerDoc.exists()) {
      await setDoc(organizerRef, {
        ...profileToSave,
        id: userId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
    } else {
      const { stats } = organizerDoc.data();
      await updateDoc(organizerRef, {
        ...profileToSave,
        stats: stats || profileToSave.stats,
        updatedAt: serverTimestamp()
      });
    }
  }
  subscribeToProfile(
    userId: string,
    onSuccess: (profile: OrganizerProfile) => void,
    onError: (error: any) => void
  ) {
    const organizerRef = doc(db, COLLECTION_NAME, userId);

    return onSnapshot(
      organizerRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const profile: OrganizerProfile = {
            id: doc.id,
            organizer: data.organizer || '',
            subtitle: data.subtitle || '',
            organizerImage: data.organizerImage || '',
            organizerContact: data.organizerContact || '',
            location: data.location || '',
            latitude: data.latitude || 0,
            longitude: data.longitude || 0,
            stats: data.stats || {
              totalEvents: 0,
              totalViews: 0,
              activeEvents: 0
            },
            createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(),
            updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : new Date()
          };
          onSuccess(profile);
        } else {
          onSuccess({
            id: userId,
            organizer: '',
            subtitle: '', 
            organizerImage: '',
            organizerContact: '',
            location: '',
            latitude: 0,
            longitude: 0,
            stats: {
              totalEvents: 0,
              totalViews: 0,
              activeEvents: 0
            },
            createdAt: new Date(),
            updatedAt: new Date()
          });
        }
      },
      onError
    );
  }
}


export const organizerService = new OrganizerService();