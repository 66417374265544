import React, { useState, useMemo, useEffect } from 'react';
import './HomePage.css';
import { sortEvents } from '../../utils/eventSorting';
import { useGeolocation } from '../../utils/hooks/useGeolocation';
import SearchBar from '../../components/SearchBar/SearchBar';
import EventCard from '../../components/event/card';
import { usePublicEvents } from '../../hooks/useEvents';
import DateTimeFilters from '../../components/filters/DateTimeFilters';
import { isToday, isTomorrow, isWeekend, getTimeOfDay } from '../../utils/dateUtils';
import { Event, EVENT_TAGS } from '../../types/event.types';
import { Link } from 'react-router-dom';
import NavMenu from '../../components/NavMenu/NavMenu';
import MapView from '../../components/map/MapView';
import { Layout, Filter } from 'lucide-react';
import { useNavMenu } from '../../hooks/useNavMenu';
import FilterPanel from '../../components/filters/FilterPanel';

const HomePage: React.FC = () => {
  console.log("HomePage rendered"); 
  const { location, loading } = useGeolocation();
  const { events, loading: eventsLoading, error: eventsError } = usePublicEvents();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [timeFilter, setTimeFilter] = useState('');
  const [distanceFilter, setDistanceFilter] = useState<number>(10);
  const [manualLocation, setManualLocation] = useState<{ latitude: number; longitude: number } | null>(null);
  const [viewMode, setViewMode] = useState<'list' | 'map'>('map');
  const [showFilters, setShowFilters] = useState(false);
  const { isOpen, toggleMenu } = useNavMenu();
  const [mapLocation, setMapLocation] = useState<{ latitude: number; longitude: number } | null>(null);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [customDate, setCustomDate] = useState<string>('');



// Utiliser soit la position de la carte, soit la géolocalisation
const effectiveLocation = useMemo(() => {
  if (viewMode === 'map' && mapLocation) {
    return mapLocation;
  }
  return manualLocation || location || undefined; 
  }, [viewMode, mapLocation, manualLocation, location]);

  

   const handleTagSelect = (tagId: string) => {
    setSelectedTags(prev => 
      prev.includes(tagId) 
        ? prev.filter(id => id !== tagId)
        : [...prev, tagId]
    );
  };


  const handleLocationClick = () => {
    setIsSearchOpen(true);
  };

  const handleDateFilterChange = (date: string) => {
    // Si "all_dates" est sélectionné, on met une chaîne vide
    const newValue = date === 'all_dates' ? '' : date;
    setDateFilter(newValue);
    console.log('Date filter changed to:', newValue);
  };
  
  const handleTimeFilterChange = (time: string) => {
    setTimeFilter(time);
    console.log('Time filter changed to:', time);
  };

  const handleDistanceFilterChange = (distance: number) => {
    setDistanceFilter(distance);
    console.log('Distance filter changed to:', distance);
  };

  const handleLocationChange = (location: { latitude: number; longitude: number }) => {
    setManualLocation(location);
    console.log('Manual location set to:', location);
  };

  const filteredEvents = useMemo(() => {
    if (!events) return [];
    
    return events.filter(event => {
      // Filtre par texte de recherche
      const matchesSearch = searchText === '' || 
        event.title.toLowerCase().includes(searchText.toLowerCase()) ||
        event.description?.toLowerCase().includes(searchText.toLowerCase());
  
      // Filtre par ville
      const matchesCity = searchCity === '' ||
        event.location?.toLowerCase().includes(searchCity.toLowerCase());
  
      // Filtre par tags (avec gestion spéciale pour les favoris)
      const matchesTags = selectedTags.length === 0 || 
      selectedTags.every(tag => {
        // Si c'est le tag "favorites", vérifier dans le localStorage
        if (tag === 'favorites') {
          return localStorage.getItem(`favorite-${event.id}`) === 'true';
        }
        // Sinon, vérifier normalement dans les tags de l'événement
        return event.tags.includes(tag);
      });

      // Filtre par date
      let matchesDate = true;
      if (dateFilter !== 'all_dates' && event.datetime) {
        const eventDate = new Date(event.datetime);
        switch (dateFilter) {
          case 'today':
            matchesDate = isToday(eventDate);
            break;
          case 'tomorrow':
            matchesDate = isTomorrow(eventDate);
            break;
          case 'weekend':
            matchesDate = isWeekend(eventDate);
            break;
            case 'custom':
              if (customDate) { // Ajout de la vérification de la date personnalisée
                const customDateTime = new Date(customDate);
                matchesDate = eventDate.getFullYear() === customDateTime.getFullYear() &&
                             eventDate.getMonth() === customDateTime.getMonth() &&
                             eventDate.getDate() === customDateTime.getDate();
                            }
                            break;
        }
      }
  
      // Filtre par heure
      let matchesTime = true;
      if (timeFilter !== 'all' && event.datetime) {
        const eventHour = new Date(event.datetime).getHours();
        switch (timeFilter) {
          case 'morning':
            matchesTime = eventHour >= 6 && eventHour < 12;
            break;
          case 'afternoon':
            matchesTime = eventHour >= 12 && eventHour < 18;
            break;
          case 'evening':
            matchesTime = eventHour >= 18 || eventHour < 6;
            break;
        }
      }
  
      return matchesSearch && matchesCity && matchesTags && matchesDate && matchesTime;
    });
  }, [events, searchText, searchCity, selectedTags, dateFilter, timeFilter, customDate]);


  // Mettre à jour le tri avec la nouvelle position
  const sortedEvents = useMemo(() => {
    if (!filteredEvents) return [];
    return sortEvents(
      filteredEvents,
      viewMode,
      effectiveLocation,
      distanceFilter
    );
  }, [filteredEvents, viewMode, effectiveLocation, distanceFilter]);

  const handleMapLocationChange = (newLocation: { latitude: number; longitude: number }) => {
    setMapLocation(newLocation);
  };


  if (eventsLoading) {
    return <div>Chargement des événements...</div>;
  }

  if (eventsError) {
    return <div>Erreur : {eventsError}</div>;
  }


  return (
    <>
      <NavMenu isOpen={isOpen} onToggle={toggleMenu} />
      <div className="home-page">
      <div className="top-bar">
  <div className="title-container">
    <h1 className="page-title">What to do</h1>
    <p className="page-subtitle">Trouvez des événements autour de vous !</p>
  </div>

  <div className="top-bar-right">
    <button 
       className="filter-btn"
       onClick={() => setIsFilterPanelOpen(!isFilterPanelOpen)}
    >
      <Filter size={16} />
      Filtres
    </button>

    <FilterPanel
            isOpen={isFilterPanelOpen}
            onClose={() => setIsFilterPanelOpen(false)}
            onDateFilterChange={handleDateFilterChange}
            onTimeFilterChange={handleTimeFilterChange}
            onDistanceFilterChange={handleDistanceFilterChange}
            onLocationChange={handleLocationChange}
            currentLocation={manualLocation || location}
            isLoadingLocation={loading}
            viewMode={viewMode}
            searchText={searchText}
            setSearchText={setSearchText}
            searchCity={searchCity}
            setSearchCity={setSearchCity}
            selectedTags={selectedTags}
            onTagSelect={handleTagSelect}
            customDate={customDate}
  onCustomDateChange={setCustomDate}
          />

    <button
     className="view-toggle-btn"
     onClick={() => setViewMode(viewMode === 'list' ? 'map' : 'list')}
    >
      <Layout size={16} />
      {viewMode === 'list' ? 'Carte' : 'Liste'}
    </button>
</div>
        </div>

        <div className="view-container">
          <div className={`list-view ${viewMode === 'list' ? 'active' : ''}`}>
            <div className="filters-section">
           
            </div>

            
            <div className="events-grid">
              {sortedEvents.map(event => (
                <EventCard key={event.id} event={event} />
              ))}
            </div>
          </div>

          <div className={`map-view ${viewMode === 'map' ? 'active' : ''}`}>
           
            <div className={`map-filters ${showFilters ? 'visible' : ''}`}>
            
            </div>
            <MapView 
          events={sortedEvents}
          onEventClick={(event) => {
            console.log('Event clicked:', event);
          }}
          onLocationChange={handleMapLocationChange}
        />
      </div>
        </div>
      </div>
    </>
);
};

export default HomePage;