import React, { useEffect, useState } from 'react';
import { Event } from '../../types/event.types';
import EventModal from './EventModal';
import './card.css';
import { useOrganizer } from '../../contexts/OrganizerContext'; 

interface EventCardProps {
  event: Event;
}

const EventCard: React.FC<EventCardProps> = ({ event }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFavorite, setIsFavorite] = useState(() => {
    const saved = localStorage.getItem(`favorite-${event.id}`);
    return saved === 'true';
  });
  const { getPublicProfile } = useOrganizer();
  const [organizerInfo, setOrganizerInfo] = useState<ReturnType<typeof getPublicProfile> extends Promise<infer T> ? T : never>(null);

  const formatDateTime = (datetime: Date | undefined) => {
    if (!datetime) return { date: 'Date non définie', time: 'Heure non définie' };
    
    const date = new Intl.DateTimeFormat('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }).format(datetime);
  
    const time = new Intl.DateTimeFormat('fr-FR', {
      hour: '2-digit',
      minute: '2-digit'
    }).format(datetime);
  
    return { date, time };
  };

  const toggleFavorite = (e?: React.MouseEvent) => {
    console.log('1. Toggle favorite called');
    if (e) {
        console.log('2. Preventing event propagation');
        e.stopPropagation();
    }
    const newValue = !isFavorite;
    console.log('3. New favorite value:', newValue);
    setIsFavorite(newValue);
    
    try {
        localStorage.setItem(`favorite-${event.id}`, String(newValue));
        console.log('4. Successfully saved to localStorage');
        console.log('5. Current localStorage value:', localStorage.getItem(`favorite-${event.id}`));
    } catch (error) {
        console.error('Error saving to localStorage:', error);
    }
};



  useEffect(() => {
    const loadOrganizerInfo = async () => {
      if (event.organizerId) {
        try {
          const profile = await getPublicProfile(event.organizerId);
          setOrganizerInfo(profile);
        } catch (error) {
          console.error('Erreur lors du chargement des informations de l\'organisateur:', error);
        }
      }
    };
    loadOrganizerInfo();
  }, [event.organizerId, getPublicProfile]);
    

  return (
    <>
    <div className="event-card">
      <div className="event-image">
        <img 
          src={event.image} 
          alt={event.title} 
          className="event-image-img"
          loading="lazy"
        />
        {event.price && (
          <div className="event-price">${event.price}</div>
        )}

<button 
      className={`favorite-button ${isFavorite ? 'active' : ''}`}
      onClick={(e) => {
          console.log('Button clicked!');
          toggleFavorite(e);
      }}
      type="button"
      style={{ zIndex: 10 }} 
>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="20" 
    height="20" 
    viewBox="0 0 24 24" 
    fill={isFavorite ? "currentColor" : "none"}
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
  </svg>
</button>
          
        <div className="event-views">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          </svg>
          {event.views} views
        </div>
      </div>

      <div className="event-content">
        <div className="event-tags">
          {event.tags.map(tag => (
            <span key={tag} className="event-tag">{tag}</span>
          ))}
        </div>

        <h3 className="event-title">{event.title}</h3>

        <div className="event-details">
          <div className="event-detail">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M8 2v4"></path>
              <path d="M16 2v4"></path>
              <rect width="18" height="18" x="3" y="4" rx="2"></rect>
              <path d="M3 10h18"></path>
            </svg>
            <span>{formatDateTime(event.datetime).date}</span>
          </div>

          <div className="event-detail">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 6 12 12 16 14"></polyline>
            </svg>
            <span>{formatDateTime(event.datetime).time}</span>
          </div>

          <div className="event-detail">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"></path>
              <circle cx="12" cy="10" r="3"></circle>
            </svg>
            <span>{event.location}</span>
          </div>
        </div>

        <div className="event-footer">
      <div className="event-organizer">
        <img 
          src={organizerInfo?.organizerImage} 
          alt={organizerInfo?.organizer} 
          className="organizer-image" 
        />
        <span className="organizer-name">{organizerInfo?.organizer}</span>
      </div>
      <button className="details-button" onClick={() => setIsModalOpen(true)}>
        Details
      </button>
    </div>
      </div>
    </div>

     <EventModal 
  event={event}
  isOpen={isModalOpen}
  onClose={() => setIsModalOpen(false)}
  isFavorite={isFavorite}
  onToggleFavorite={toggleFavorite}
  isMapView={false} 
 />
</>
  );
};

export default EventCard;