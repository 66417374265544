/// <reference types="@types/google.maps" />

import React, { useState, FormEvent } from 'react';
import { Event, EVENT_TAGS } from '../../../types/event.types';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import StepIndicator from './StepIndicator';
import BasicInfoStep from './steps/BasicInfoStep';
import DetailsStep from './steps/DetailsStep';
import { useOrganizer } from '../../../contexts/OrganizerContext';
import PreviewStep from './steps/PreviewStep';
import { cloudinaryService } from '../../../services/cloudinary.service';
import './EventForm.css';


export interface FormData {
    organizerId: string; 
    title: string;
    description: string;
    date: string;
    time: string;
    location: string;
    latitude: number;
    longitude: number;
    tags: string[];
    image: string;
    price: number;
    views: number;
    organizer: string;
    organizerImage: string;
    organizerContact: string;
    status: 'active' | 'draft' | 'past';
    isRecurring: boolean;
    frequency?: 'daily' | 'weekly' ;
    startDate?: string;
    endType?: 'date' | 'occurrences';
    endDate?: string;
    occurrences?: number;
    weekDays?: string[];
}



export const initialFormData: FormData = {
  organizerId: '', 
    title: '',
    description: '',
    date: '', 
    time: '',
    location: '',
    latitude: 0,
    longitude: 0,
    tags: [],
    image: '',
    price: 0,
    views: 0,
    organizer: '',
    organizerImage: '',
    organizerContact: '',
    status: 'draft',
    isRecurring: false,
    frequency: 'daily',
    startDate: '',
    endType: 'date',
    endDate: '',
    occurrences: 1,
    weekDays: []
};

interface EventFormProps {
  event?: Event;
  organizerId: string; 
  onSubmit: (eventData: Omit<Event, 'id'> & { id?: string }) => void; 
  onClose: () => void;
}

const FORM_STEPS = [
  {
    title: 'Informations de base',
    description: 'Titre, date et lieu'
  },
  {
    title: 'Détails',
    description: 'Description, prix et tags'
  },
  {
    title: 'Vérification',
    description: 'Prévisualisation et publication'
  }
];

const EventForm: React.FC<EventFormProps> = ({ event, organizerId, onSubmit, onClose }) => {
  const { organizerProfile } = useOrganizer();
  const [currentStep, setCurrentStep] = useState(0);
  const formatDateForInput = (datetime: Date | undefined | null): { date: string, time: string } => {
    if (!datetime) return { date: '', time: '' };
    try {
      const d = new Date(datetime);
      if (isNaN(d.getTime())) return { date: '', time: '' };
      
      const date = d.toISOString().split('T')[0];
      const time = d.toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });
      
      return { date, time };
    } catch {
      return { date: '', time: '' };
    }
  };

const [formData, setFormData] = useState<FormData>({
  ...initialFormData,
  organizerId,
  location: event?.location || organizerProfile?.location || '',
  latitude: event?.latitude || organizerProfile?.latitude || 0,
  longitude: event?.longitude || organizerProfile?.longitude || 0,
  ...(event && {
    ...event,
    ...(event.datetime && {
      ...formatDateForInput(event.datetime)
    })
  })
});


  const [isDraft, setIsDraft] = useState(event ? event.status === 'draft' : true);
  const [errors, setErrors] = useState<Partial<Record<keyof FormData, string>>>({});
  

  const validateCurrentStep = (isDraft: boolean = false): boolean => {
    const newErrors: Partial<Record<keyof FormData, string>> = {};

    switch (currentStep) {
      case 0:
        if (!formData.title) newErrors.title = 'Le titre est requis';
        if (!isDraft && !formData.date) newErrors.date = 'La date est requise pour publier l\'événement';
        break;
      case 1:
        if (formData.price < 0) newErrors.price = 'Le prix ne peut pas être négatif';
        if (formData.organizerContact && !validateContact(formData.organizerContact)) {
          newErrors.organizerContact = 'Contact invalide';
        }
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleNext = () => {
    const isValid = validateCurrentStep(true);
    if (isValid) {
      setCurrentStep(prev => Math.min(prev + 1, FORM_STEPS.length - 1));
    }
  };

  const handlePrevious = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleSubmit = async (e: React.MouseEvent, isDraft: boolean) => {
    e.preventDefault();
    setIsSubmitting(true);
    console.log(`handle${isDraft ? 'SaveAsDraft' : 'Publish'} called`);
    console.log('formData avant soumission:', formData);
    
    try {
      if (!validateCurrentStep(isDraft)) {
        setIsSubmitting(false); // Assurez-vous de réinitialiser l'état ici aussi
        return; // Sortir si la validation échoue
      }
        // Créer la date de l'événement
        let eventDateTime = undefined;
        if (formData.date) {
          const [year, month, day] = formData.date.split('-').map(Number);
          const [hours, minutes] = formData.time ? formData.time.split(':').map(Number) : [0, 0];
          eventDateTime = new Date(year, month - 1, day, hours, minutes);
        }
  
        let finalImageUrl = formData.image;
      if (formData.image.startsWith('blob:') || formData.image.startsWith('data:')) {
        try {
          const response = await fetch(formData.image);
          const blob = await response.blob();
          const file = new File([blob], 'image.jpg', { type: blob.type });
          finalImageUrl = await cloudinaryService.uploadImage(file);
        } catch (error) {
          console.error('Erreur lors de l\'upload de l\'image:', error);
          finalImageUrl = ''; // En cas d'erreur, pas d'image
        }
      }
  
        console.log('URL finale de l\'image:', finalImageUrl);
  
        const submissionData = {
          ...formData,
          image: finalImageUrl,
          date: eventDateTime,
          status: isDraft ? 'draft' as const : 'active' as const,
          ...(event?.id && { id: event.id })
        };
  
        console.log('Data finale envoyée:', submissionData);
        await onSubmit(submissionData);
        onClose();
      } catch (error) {
        console.error('Erreur lors de la soumission:', error);
      } finally {
        setIsSubmitting(false); // Fin du chargement dans tous les cas
    }
  };

  const handleSaveAsDraft = (e: React.MouseEvent) => handleSubmit(e, true);
  const handlePublish = (e: React.MouseEvent) => handleSubmit(e, false);

 
const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries: ['places']
  });
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
 
  const validateContact = (contact: string): boolean => {
    if (!contact) return true;
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[\d\s-]{10,}$/;
    const instaRegex = /^@[a-zA-Z0-9._]{1,30}$/;
    const whatsappRegex = /^(wa\.me\/|whatsapp:\/\/)?(\+[\d\s-]{8,})$/;
    
    return emailRegex.test(contact) || 
           phoneRegex.test(contact) || 
           instaRegex.test(contact) || 
           whatsappRegex.test(contact);
  };

  const onPlaceSelected = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const location = place.geometry?.location;
      
      if (location) {
        setFormData(prev => ({
          ...prev,
          location: place.formatted_address || '',
          latitude: location.lat(),
          longitude: location.lng()
        }));
      }
    }
  };

  const onLoadFailed = () => {
    setErrors(prev => ({
      ...prev,
      location: "Impossible de charger le service de localisation"
    }));
  };



  const handleTagToggle = (tagId: string) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.includes(tagId)
        ? prev.tags.filter(id => id !== tagId)
        : [...prev.tags, tagId]
    }));
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <BasicInfoStep
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            autocomplete={autocomplete}
            setAutocomplete={setAutocomplete}
            onPlaceSelected={onPlaceSelected}
            isLoaded={isLoaded}
          />
        );
      case 1:
        return (
          <DetailsStep
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            handleTagToggle={handleTagToggle}
          />
        );
      case 2:
        return <PreviewStep formData={formData} />;
      default:
        return null;
    }
  };  

  return (
    <div className="modal-overlay" onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}>
      <div className="modal-content">
      {isSubmitting && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Envoi en cours...</p>
          <p>Veuillez patienter pendant l'upload de l'image</p>
        </div>
      )}
        <button className="modal-close" onClick={onClose}>×</button>
        
        <div className="event-form">
          <StepIndicator
            currentStep={currentStep}
            steps={FORM_STEPS}
          />

          {renderCurrentStep()}

          <div className="form-navigation">
            {currentStep > 0 && (
              <button
                type="button"
                className="previous-button"
                onClick={handlePrevious}
              >
                Précédent
              </button>
            )}
            
            {currentStep < FORM_STEPS.length - 1 ? (
              <button
                type="button"
                className="next-button"
                onClick={handleNext}
              >
                Suivant
              </button>
            ) : (
              <div className="form-actions">
                <button 
                  type="button" 
                  className="draft-button"
                  onClick={handleSaveAsDraft}
                  disabled={isSubmitting}
                >
                  Sauvegarder comme brouillon
                </button>
                <button 
                  type="button"
                  className="publish-button"
                  onClick={handlePublish}
                  disabled={isSubmitting}
                >
                  {event?.status === 'active' ? 'Mettre à jour' : 'Publier'}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};



export default EventForm;