import { useState, useEffect, useCallback } from 'react';
import { Event } from '../types/event.types';
import { db } from '../firebase/config';
import {
  collection,
  query,
  onSnapshot,
  where,
  QuerySnapshot,
  DocumentData,
  FirestoreError
} from 'firebase/firestore';

// Hook pour la page d'accueil (visiteurs)
export const usePublicEvents = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const eventsRef = collection(db, 'events');
    const now = new Date();

    // Filtre pour les événements avec date future
    const eventsQuery = query(
      eventsRef,
      where('date', '>=', now), 
      where('status', '!=', 'draft')
    );

    const unsubscribe = onSnapshot(
      eventsQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const eventsList = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            datetime: data.date?.toDate(),
            createdAt: data.createdAt?.toDate(),
            updatedAt: data.updatedAt?.toDate()
          };
        }) as Event[];

        setEvents(eventsList);
        setLoading(false);
      },
      (err: FirestoreError) => {
        console.error('Erreur lors du chargement des événements:', err);
        setError(err.message);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  return { events, loading, error };
};
export const useOrganizerEvents = (organizerId: string) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!organizerId) {
      setEvents([]);
      setLoading(false);
      return;
    }

    const eventsRef = collection(db, 'events');

    // Query pour tous les événements de l'organisateur
    const eventsQuery = query(
      eventsRef,
      where('organizerId', '==', organizerId)
    );

    const unsubscribe = onSnapshot(
      eventsQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const eventsList = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            datetime: data.date?.toDate(),
            createdAt: data.createdAt?.toDate(),
            updatedAt: data.updatedAt?.toDate()
          } as Event;
        });

        setEvents(eventsList);
        setLoading(false);
      },
      (err: FirestoreError) => {
        console.error('Erreur lors du chargement des événements:', err);
        setError(err.message);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [organizerId]);

  const getActiveEvents = useCallback(() => 
      events.filter(event => 
      event?.datetime && 
      new Date(event.datetime) >= new Date() && 
      event.status === 'active'
  )
  , [events]);

  const getPastEvents = useCallback(() => 
    events.filter(event => 
      event.datetime && 
      new Date(event.datetime) < new Date() && 
      event.status === 'active'
    )
  , [events]);

  const getDraftEvents = useCallback(() => 
    events.filter(event => event.status === 'draft')
  , [events]);

  return {
    events,
    activeEvents: getActiveEvents(),
    pastEvents: getPastEvents(),
    draftEvents: getDraftEvents(),
    loading,
    error
  };
};