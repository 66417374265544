import React from 'react';
import { Calendar, TrendingUp, Users, Eye } from 'lucide-react';
import { Event } from '../../../types/event.types';
import './OrganizerStat.css';

interface OrganizerStatProps {
  events: Event[];
}

const OrganizerStat: React.FC<OrganizerStatProps> = ({ events }) => {
  // Calcul des statistiques
  const totalEvents = events.length;
  const totalViews = events.reduce((sum, event) => sum + (event.views || 0), 0);
  const averageViews = totalEvents > 0 ? Math.round(totalViews / totalEvents) : 0;
  const upcomingEvents = events.filter(event => {
    if (!event.datetime) return false; // Ignorer les événements sans date
    return new Date(event.datetime) > new Date();
  }).length;
  
  // Trouver l'événement le plus populaire
  const mostPopularEvent = events.length > 0 
    ? events.reduce((prev, current) => 
      ((prev.views || 0) > (current.views || 0)) ? prev : current
      , events[0])
    : null;

  return (
    <div className="stats-container">
      <h2 className="stats-title">Statistiques</h2>
      
      <div className="stats-grid">
        <div className="stat-card">
          <div className="stat-content">
            <div className="stat-info">
              <p className="stat-label">Total Événements</p>
              <p className="stat-value">{totalEvents}</p>
            </div>
            <div className="stat-icon-container green">
              <Calendar className="icon" />
            </div>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-content">
            <div className="stat-info">
              <p className="stat-label">Vues Totales</p>
              <p className="stat-value">{totalViews}</p>
            </div>
            <div className="stat-icon-container blue">
              <Eye className="icon" />
            </div>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-content">
            <div className="stat-info">
              <p className="stat-label">Vues Moyennes</p>
              <p className="stat-value">{averageViews}</p>
            </div>
            <div className="stat-icon-container purple">
              <TrendingUp className="icon" />
            </div>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-content">
            <div className="stat-info">
              <p className="stat-label">Événements à venir</p>
              <p className="stat-value">{upcomingEvents}</p>
            </div>
            <div className="stat-icon-container green">
              <Users className="icon" />
            </div>
          </div>
        </div>
      </div>

      {mostPopularEvent && (
        <div className="popular-event-card">
          <h3>Événement le plus populaire</h3>
          <div className="popular-event-content">
            <img 
              src={mostPopularEvent.image} 
              alt={mostPopularEvent.title}
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = '/default-event-image.jpg';
              }}
            />
            <div className="popular-event-info">
              <h4>{mostPopularEvent.title}</h4>
              <p>{mostPopularEvent.views} vues</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrganizerStat;