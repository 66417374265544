import { useState, useEffect } from 'react';

interface Location {
  latitude: number;
  longitude: number;
  city?: string;
  error?: string;
}

export const useGeolocation = () => {
  const [location, setLocation] = useState<Location | null>(null);
  const [loading, setLoading] = useState(true);

  const getCityName = async (lat: number, lon: number) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
      );
      const data = await response.json();
      return data.address.city || data.address.town || data.address.village;
    } catch (error) {
      console.error('Erreur lors de la récupération de la ville:', error);
      return undefined;
    }
  };

  useEffect(() => {
    if (!navigator.geolocation) {
      setLocation({ 
        latitude: 0, 
        longitude: 0, 
        error: "La géolocalisation n'est pas supportée par votre navigateur" 
      });
      setLoading(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const cityName = await getCityName(
          position.coords.latitude,
          position.coords.longitude
        );
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          city: cityName
        });
        setLoading(false);
      },
      (error) => {
        setLocation({ 
          latitude: 0, 
          longitude: 0, 
          error: "Impossible d'obtenir votre position" 
        });
        setLoading(false);
      }
    );
  }, []);

  return { location, loading };
};