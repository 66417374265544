import React, { useEffect } from 'react';
import { FormData } from '../EventForm';
import { EVENT_TAGS } from '../../../../types/event.types';
import { useOrganizer } from '../../../../contexts/OrganizerContext';
import { cloudinaryService } from '../../../../services/cloudinary.service';


interface DetailsStepProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  errors: Partial<Record<keyof FormData, string>>;
  handleTagToggle: (tagId: string) => void;
}

const DetailsStep: React.FC<DetailsStepProps> = ({
  formData,
  setFormData,
  errors,
  handleTagToggle
}) => {
  const { organizerProfile } = useOrganizer();  

  useEffect(() => {
    if (!formData.organizerContact && organizerProfile?.organizerContact) {
      setFormData(prev => ({
        ...prev,
        organizerContact: organizerProfile.organizerContact
      }));
    }
  }, [organizerProfile]);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        // Afficher une preview temporaire immédiatement
        const tempUrl = URL.createObjectURL(file);
        setFormData(prev => ({ ...prev, image: tempUrl }));
  
        // Upload vers Cloudinary
        const cloudinaryUrl = await cloudinaryService.uploadImage(file);
        console.log('URL Cloudinary reçue:', cloudinaryUrl); // Pour déboguer
        
        // Mettre à jour avec l'URL permanente
        if (cloudinaryUrl) {
          setFormData(prev => ({ ...prev, image: cloudinaryUrl }));
          console.log('FormData mis à jour avec URL Cloudinary'); // Pour déboguer
        } else {
          console.error('Pas d\'URL reçue de Cloudinary');
        }
        
        // Nettoyer l'URL temporaire
        URL.revokeObjectURL(tempUrl);
      } catch (error) {
        console.error('Erreur lors de l\'upload de l\'image:', error);
        // Réinitialiser l'image en cas d'erreur
        setFormData(prev => ({ ...prev, image: '' }));
      }
    }
  };


  return (
    <div className="form-step">
      <div className="form-group">
        <label htmlFor="description">Description de l'événement</label>
        <textarea
          id="description"
          value={formData.description}
          onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
          className={errors.description ? 'error' : ''}
          rows={4}
          placeholder="Décrivez votre événement en détail..."
        />
        {errors.description && <span className="error-message">{errors.description}</span>}
      </div>

      <div className="form-group">
        <label htmlFor="price">Prix (€)</label>
        <input
          type="number"
          id="price"
          value={formData.price}
          onChange={e => setFormData(prev => ({ ...prev, price: Number(e.target.value) }))}
          min="0"
          step="0.01"
          className={errors.price ? 'error' : ''}
          placeholder="0.00"
        />
        {errors.price && <span className="error-message">{errors.price}</span>}
      </div>

      <div className="form-group">
        <label htmlFor="image">Image/Flyer</label>
        <div className="image-upload-container">
          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={handleImageUpload}  // Utiliser la nouvelle fonction
          />
          {formData.image && (
            <div className="image-preview">
              <img src={formData.image} alt="Preview" />
            </div>
          )}
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="organizerContact">Contact</label>
        <input
          type="text"
          id="organizerContact"
          value={formData.organizerContact}
          onChange={e => setFormData(prev => ({ ...prev, organizerContact: e.target.value }))}
          className={errors.organizerContact ? 'error' : ''}
          placeholder={organizerProfile?.organizerContact || "Email ou numéro de téléphone"}
        />
        {errors.organizerContact && <span className="error-message">{errors.organizerContact}</span>}
        {organizerProfile?.organizerContact && !formData.organizerContact && (
          <span className="help-text">Utiliser mon contact : {organizerProfile.organizerContact}</span>
        )}
      </div>

       <div className="form-group">
        <label>Tags</label>
        <div className="tags-grid">
          {EVENT_TAGS.filter(tag => tag.id !== 'favorites').map(tag => (
            <button
              type="button"
              key={tag.id}
              onClick={() => handleTagToggle(tag.id)}
              className={`tag-button ${formData.tags.includes(tag.id) ? 'active' : ''}`}
            >
              <span className="tag-icon">{tag.icon}</span>
              <span className="tag-label">{tag.label}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailsStep;