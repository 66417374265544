import React from 'react';
import { FormData } from '../EventForm';
import { EVENT_TAGS } from '../../../../types/event.types';

interface PreviewStepProps {
  formData: FormData;
}

const PreviewStep: React.FC<PreviewStepProps> = ({ formData }) => {
  return (
    <div className="preview-step">
      <div className="preview-header">
        <h2>Vérification des informations</h2>
        <p>Vérifiez que toutes les informations sont correctes avant de publier votre événement.</p>
      </div>

      <div className="preview-section">
        <h3>Informations principales</h3>
        <div className="preview-grid">
          <div className="preview-item">
            <label>Titre</label>
            <p>{formData.title}</p>
          </div>
          <div className="preview-item">
            <label>Date et heure</label>
            <p>{formData.date} {formData.time}</p>
          </div>
          <div className="preview-item">
            <label>Lieu</label>
            <p>{formData.location}</p>
          </div>
        </div>
      </div>

      <div className="preview-section">
        <h3>Détails</h3>
        <div className="preview-grid">
          <div className="preview-item">
            <label>Description</label>
            <p>{formData.description}</p>
          </div>
          <div className="preview-item">
            <label>Prix</label>
            <p>{formData.price > 0 ? `${formData.price}€` : 'Gratuit'}</p>
          </div>
          <div className="preview-item">
            <label>Tags</label>
            <div className="preview-tags">
              {formData.tags.map(tagId => {
                const tag = EVENT_TAGS.find(t => t.id === tagId);
                return tag ? (
                  <span key={tagId} className="preview-tag">
                    {tag.icon} {tag.label}
                  </span>
                ) : null;
              })}
            </div>
          </div>
        </div>
      </div>

      {formData.image && (
        <div className="preview-section">
          <h3>Image</h3>
          <div className="preview-image">
            <img src={formData.image} alt="Event" />
          </div>
        </div>
      )}

      <div className="preview-section">
        <h3>Contact</h3>
        <p>{formData.organizerContact}</p>
      </div>
    </div>
  );
};

export default PreviewStep;