import React, { useState, useEffect, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import { calculateMapBounds } from '../../utils/mapUtils';
import { Event } from '../../types/event.types';
import { useGeolocation } from '../../utils/hooks/useGeolocation';
import { sortEvents } from '../../utils/eventSorting';
import EventModal from '../event/EventModal';
import 'leaflet/dist/leaflet.css';
import './MapView.css';
import L from 'leaflet';

function MapUpdater({ 
  bounds, 
  zoom, 
  shouldUpdate 
}: { 
  bounds?: L.LatLngBounds; 
  zoom: number;
  shouldUpdate: boolean;
}) {
  const map = useMap();
  
  useEffect(() => {
    if (shouldUpdate && bounds && bounds.isValid()) {
      map.fitBounds(bounds, {
        padding: [50, 50],
        maxZoom: zoom
      });
    }
  }, [bounds, zoom, map, shouldUpdate]);
  
  return null;
}

// Composant pour détecter les mouvements de la carte
function MapEventHandler({ onMoveEnd }: { onMoveEnd: (center: [number, number]) => void }) {
  const map = useMapEvents({
    moveend: () => {
      const center = map.getCenter();
      onMoveEnd([center.lat, center.lng]);
    },
  });
  return null;
}

interface MapViewProps {
  events: Event[];
  onEventClick: (event: Event) => void;
  onLocationChange?: (location: { latitude: number; longitude: number }) => void;
}


const MapView: React.FC<MapViewProps> = ({ events, onEventClick, onLocationChange }) => {
  const { location } = useGeolocation();
  const [initialLoad, setInitialLoad] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favorites, setFavorites] = useState<Set<string>>(new Set());
  const [highlightedEventId, setHighlightedEventId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  
  // Centre initial de la carte basé sur la géolocalisation
  const mapCenter = useMemo(() => {
    if (location?.latitude && location?.longitude) {
      return [location.latitude, location.longitude] as [number, number];
    }
    return [46.227638, 2.213749] as [number, number]; // Centre de la France par défaut
  }, [location]);

    // Trier et filtrer les événements pour la vue carte
    const sortedEvents = useMemo(() => 
      sortEvents(
        events,
        'map',
        location ? {
          latitude: location.latitude,
          longitude: location.longitude
        } : undefined
      ),
    [events, location]
  );

  const { bounds, zoom } = useMemo(() => 
    calculateMapBounds(
      sortedEvents, 
      location ? {
        latitude: location.latitude,
        longitude: location.longitude
      } : undefined
    ),
    [sortedEvents, location]
  );

  const handleMoveEnd = (center: [number, number]) => {
    setIsLoading(true);
    // Petit délai pour éviter trop d'appels lors du déplacement
    setTimeout(() => {
      onLocationChange?.({
        latitude: center[0],
        longitude: center[1]
      });
      setIsLoading(false);
    }, 300);
  };

  const handleEventClick = (event: Event) => {
    setSelectedEvent(event);
    const saved = localStorage.getItem(`favorite-${event.id}`);
    setIsFavorite(saved === 'true');
    onEventClick(event);
  };

  const handleToggleFavorite = () => {
    if (selectedEvent) {
      const newValue = !isFavorite;
      setIsFavorite(newValue);
      localStorage.setItem(`favorite-${selectedEvent.id}`, String(newValue));
      
      const newFavorites = new Set(favorites);
      if (newValue) {
        newFavorites.add(selectedEvent.id);
      } else {
        newFavorites.delete(selectedEvent.id);
      }
      setFavorites(newFavorites);
    }
  };

  // Charger les favoris au montage du composant
  useEffect(() => {
    const loadFavorites = () => {
      const favs = new Set<string>();
      events.forEach(event => {
        if (localStorage.getItem(`favorite-${event.id}`) === 'true') {
          favs.add(event.id);
        }
      });
      setFavorites(favs);
    };
    loadFavorites();
  }, [events]);

  // Mémoriser la création de l'icône personnalisée
  const createCustomIcon = useMemo(() => {
    return (isHighlighted: boolean = false, isFavorite: boolean = false, isSelected: boolean = false) => {
      return L.divIcon({
        className: 'custom-marker',
        html: `<div class="marker-pin ${isHighlighted ? 'highlight' : ''} ${isFavorite ? 'favorite' : ''} ${isSelected ? 'selected' : ''}"></div>`,
        iconSize: isSelected ? [36, 36] : [30, 30],
        iconAnchor: isSelected ? [18, 36] : [15, 30],
        popupAnchor: [0, -30]
      });
    };
  }, []);

  const handleModalClose = () => {
    setSelectedEvent(null);
    setHighlightedEventId(null);
  };

  useEffect(() => {
    if (initialLoad && bounds && bounds.isValid()) {
      setInitialLoad(false);
    }
  }, [bounds, initialLoad]);

  return (
    <>
         <div className="map-container">
        <MapContainer
          center={mapCenter}
          zoom={13}
          style={{ height: '100%', width: '100%' }}
        >
          <MapUpdater 
            bounds={bounds} 
            zoom={zoom} 
            shouldUpdate={initialLoad}
          />
          <MapEventHandler onMoveEnd={handleMoveEnd} /> 
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          />
          
          {location?.latitude && location?.longitude && (
            <Marker 
              position={[location.latitude, location.longitude]}
              icon={L.divIcon({
                className: 'user-location-marker',
                html: `<div class="user-marker-pin"></div>`,
                iconSize: [20, 20],
                iconAnchor: [10, 10]
              })}
            >
              <Popup>Vous êtes ici</Popup>
            </Marker>
          )}

{sortedEvents.map(event => (
            // Utiliser sortedEvents au lieu de events
            event.latitude && event.longitude ? (
              <Marker
                key={event.id}
                position={[event.latitude, event.longitude]}
                icon={createCustomIcon(
                  event.id === highlightedEventId,
                  favorites.has(event.id),
                  event.id === selectedEvent?.id
                )}
                eventHandlers={{
                  click: () => {
                    setHighlightedEventId(event.id);
                    handleEventClick(event);
                  }
                }}
              />
            ) : null
          ))}
         {isLoading && (
            <div className="map-loading-indicator">
              Chargement des événements...
            </div>
          )}
        </MapContainer>
      </div>

      {selectedEvent && (
        <EventModal
          event={selectedEvent}
          isOpen={!!selectedEvent}
          onClose={handleModalClose}
          isFavorite={isFavorite}
          onToggleFavorite={handleToggleFavorite}
          isMapView={true}
        />
      )}
    </>
  );
};

export default MapView;