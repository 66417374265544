import React from 'react';

interface StepIndicatorProps {
    currentStep: number;
    steps: {
      title: string;
      description: string;
    }[];
  }
  
  const StepIndicator: React.FC<StepIndicatorProps> = ({ currentStep, steps }) => {
    return (
      <div className="step-indicator">
        {steps.map((step, index) => (
          <div key={step.title} className="step-item">
            {index > 0 && (
              <div className={`step-line ${index <= currentStep ? 'active' : ''}`} />
            )}
            <div className={`step-circle ${index <= currentStep ? 'active' : ''}`}>
              {index < currentStep ? (
                <svg className="check-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              ) : (
                index + 1
              )}
            </div>
            <div className="step-content">
              <div className="step-label">{step.title}</div>
              <div className="step-description">{step.description}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

export default StepIndicator;