export const formatDate = (date: Date): string => {
  return date.toISOString().split('T')[0];
};

// Vérifie si une date est aujourd'hui
export const isToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

// Vérifie si une date est demain
export const isTomorrow = (date: Date): boolean => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return (
    date.getDate() === tomorrow.getDate() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getFullYear() === tomorrow.getFullYear()
  );
};

// Vérifie si une date est ce weekend
export const isWeekend = (date: Date): boolean => {
  const today = new Date();

  // Trouve le prochain vendredi
  const friday = new Date(today);
  while (friday.getDay() !== 5) {
    friday.setDate(friday.getDate() + 1);
  }
  friday.setHours(0, 0, 0, 0);

  // Trouve le prochain dimanche
  const sunday = new Date(friday);
  sunday.setDate(friday.getDate() + 2);
  sunday.setHours(23, 59, 59, 999);

  // Compare les dates
  return date.getTime() >= friday.getTime() && date.getTime() <= sunday.getTime();
};

// Modifié pour accepter un nombre (heure) au lieu d'une chaîne
export const getTimeOfDay = (hour: number): string => {
  if (hour >= 5 && hour < 12) return 'morning';
  if (hour >= 12 && hour < 18) return 'afternoon';
  return 'evening';
};