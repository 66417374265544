import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import OrganizerPage from './pages/OrganizerPage/OrganizerPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';
import { OrganizerProvider } from './contexts/OrganizerContext';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <AuthProvider>
      <OrganizerProvider>
        <BrowserRouter>
          <div className="App">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route 
                path="/organizer" 
                element={
                  <PrivateRoute>
                    <OrganizerPage />
                  </PrivateRoute>
                } 
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Toaster position="top-right" />
          </div>
        </BrowserRouter>
      </OrganizerProvider>
    </AuthProvider>
  );
}

export default App;