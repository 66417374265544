export type EventTag = {
    id: string;
    label: string;
    icon: string;
  };
  
  export interface DistanceFilter {
    value: number;
    maxDistance: number;
  }
  
  
  export interface Event {
    id: string;
    organizerId: string;     
    title: string;                // Obligatoire - identifiant minimal
    description?: string;         // Optionnel en draft
    datetime?: Date;            // Optionnel en draft
    location?: string;            // Optionnel en draft
    latitude?: number;            // Optionnel en draft
    longitude?: number;           // Optionnel en draft
    tags: string[];               // Peut être un tableau vide []
    image?: string;               // Optionnel
    price?: number;               // Déjà optionnel
    views?: number;                // Obligatoire (défaut à 0)
    status: 'active' | 'draft'; // Obligatoire
    attendees?: number;           // Déjà optionnel
    createdAt?: Date;
    updatedAt?: Date;
  }

  export type ActiveView = 'list' | 'create' | 'stats';
export type ActiveTab = 'active' | 'draft' | 'past';

  export type DateFilterValue = 'all_dates' | 'today' | 'tomorrow' | 'weekend' | 'custom' | '';

  export interface DateFilter {
    id: string;
    label: string;
    value: DateFilterValue;
  }
  
  export interface TimeFilter {
    id: string;
    label: string;
    icon: string;
    value: 'morning' | 'afternoon' | 'evening' | 'all';
  }

  export const DATE_FILTERS: DateFilter[] = [
    { id: 'all_dates', label: 'All dates', value: 'all_dates' },
    { id: 'today', label: 'Today', value: 'today' },
    { id: 'tomorrow', label: 'Tomorrow', value: 'tomorrow' },
    { id: 'weekend', label: 'This Weekend', value: 'weekend' },
    { id: 'custom', label: 'Choose Date', value: 'custom' }
  ];
  
  export const TIME_FILTERS: TimeFilter[] = [
    { id: 'morning', label: 'Morning', icon: '🌅', value: 'morning' },
    { id: 'afternoon', label: 'Afternoon', icon: '☀️', value: 'afternoon' },
    { id: 'evening', label: 'Evening', icon: '🌙', value: 'evening' }
  ];
  
  export const EVENT_TAGS: EventTag[] = [
    { id: 'favorites', label: 'Favoris', icon: '❤️' },
    { id: 'popular', label: 'Popular', icon: '🔥' },
    { id: 'today', label: 'Today', icon: '📅' },
    { id: 'free', label: 'Free', icon: '🎟️' },
    { id: 'music', label: 'Music', icon: '🎵' },
    { id: 'sports', label: 'Sports', icon: '⚽' },
    { id: 'art', label: 'Art', icon: '🎨' },
    { id: 'food', label: 'Food', icon: '🍽️' },
    { id: 'culture', label: 'Culture', icon: '🏛️' },
  ];
  

