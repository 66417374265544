import { 
    getAuth, 
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    GoogleAuthProvider, 
    signInWithPopup,
    User,
    sendPasswordResetEmail
  } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { OrganizerProfile }  from '../types/organizer.types';
import { organizerService } from './organizer.service';
import { auth as firebaseAuth } from './config';
  
  export class AuthService {
    async register(email: string, password: string) {
      try {
        const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);
        
        // Créer le profil organisateur dans Firestore
        const organizerData: OrganizerProfile = {
          id: userCredential.user.uid,
          organizer: '',  // Sera rempli plus tard par l'utilisateur
          organizerContact: email,
          location: '',
          stats: {
            totalEvents: 0,
            totalViews: 0,
            activeEvents: 0
          },
          createdAt: new Date(),
          updatedAt: new Date()
        };
    
        await organizerService.saveProfile(userCredential.user.uid, organizerData);
        
        return userCredential.user;
      } catch (error) {
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case 'auth/email-already-in-use':
              throw new Error('Cette adresse email est déjà utilisée');
            case 'auth/invalid-email':
              throw new Error('Adresse email invalide');
            case 'auth/weak-password':
              throw new Error('Le mot de passe doit contenir au moins 6 caractères');
            default:
              throw new Error('Erreur lors de l\'inscription');
          }
        }
        throw error;
      }
    }
  
    async signInWithGoogle() {
      try {
        const provider = new GoogleAuthProvider();
        const userCredential = await signInWithPopup(firebaseAuth, provider);
        
        // Créer le profil organisateur s'il n'existe pas déjà
        const organizerData: OrganizerProfile = {
          id: userCredential.user.uid,
          organizer: userCredential.user.displayName || '',
          organizerContact: userCredential.user.email || '',
          location: '',
          stats: {
            totalEvents: 0,
            totalViews: 0,
            activeEvents: 0
          },
          createdAt: new Date(),
          updatedAt: new Date()
        };
  
        // Vérifier si le profil existe déjà
        const existingProfile = await organizerService.getProfile(userCredential.user.uid);
        if (!existingProfile) {
          await organizerService.saveProfile(userCredential.user.uid, organizerData);
        }
  
        return userCredential.user;
      } catch (error) {
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case 'auth/popup-closed-by-user':
              throw new Error('Connexion annulée');
            case 'auth/account-exists-with-different-credential':
              throw new Error('Un compte existe déjà avec cette adresse email');
            default:
              throw new Error('Erreur lors de la connexion avec Google');
          }
        }
        throw error;
      }
    }

    async login(email: string, password: string) {
      try {
        const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password);
        return userCredential.user;
      } catch (error) {
        console.error('Erreur lors de la connexion:', error);
        throw error;
      }
    }
  
    async logout() {
      try {
        await signOut(firebaseAuth);
      } catch (error) {
        console.error('Erreur lors de la déconnexion:', error);
        throw error;
      }
    }
  
    async resetPassword(email: string) {
      try {
        await sendPasswordResetEmail(firebaseAuth, email);
      } catch (error) {
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case 'auth/user-not-found':
              throw new Error('Aucun compte associé à cette adresse email');
            case 'auth/invalid-email':
              throw new Error('Adresse email invalide');
            default:
              throw new Error('Erreur lors de l\'envoi du mail de réinitialisation');
          }
        }
        throw error;
      }
    }

    onAuthStateChanged(callback: (user: User | null) => void) {
      return onAuthStateChanged(firebaseAuth, callback);
    }
  }
  

  
  export const authService = new AuthService();