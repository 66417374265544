/* 🔄 REMPLACER TOUT LE FICHIER */

import React, { useRef, useEffect } from 'react';
import './SearchBar.css';

interface SearchBarProps {
  isExpanded: boolean;
  setIsExpanded: (expanded: boolean) => void;
  searchText: string;  
  setSearchText: (text: string) => void;
  searchCity: string;
  setSearchCity: (city: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ isExpanded, setIsExpanded, searchText, setSearchText, searchCity, setSearchCity  }) => {
  const searchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsExpanded]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);

    // Si le texte contient une ville, mettez à jour la ville recherchée
    if (value.includes(',')) {
      const city = value.split(',')[1].trim();
      setSearchCity(city);
    }
  };
  return (
    <div className="search-bar" ref={searchRef}>
      {isExpanded && (
        <div className={`search-container ${isExpanded ? 'expanded' : ''}`}>
          <input
            type="text"
            placeholder="Rechercher une expérience, un lieu, ..."
            className="search-input"
            value={searchText}  
            onChange={(e) => setSearchText(e.target.value)}
            autoFocus={isExpanded}
          />
        </div>
      )}
      <button 
        className="search-icon-button"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        🔍
      </button>
    </div>
  );
};

export default SearchBar;