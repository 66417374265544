import { Event } from '../types/event.types';


// Types pour nos règles
interface SortingRule {
  name: string;
  weight: number;
  calculate: (event: Event, userLocation?: { latitude: number; longitude: number }) => number;
}

interface ViewConfig {
  rules: SortingRule[];
  options: {
    maxDistance: number; 
    userMaxDistance?: number;    // en km
    maxEvents: number;      // nombre max d'événements
    maxDays: number;        // horizon temporel en jours
  };
}

// Fonctions utilitaires
const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const R = 6371; // Rayon de la Terre en km
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;
};

// Configuration pour chaque vue
export const viewConfigs: Record<'list' | 'map', ViewConfig> = {
  list: {
    rules: [
      {
        name: 'distance',
        weight: 0.4,
        calculate: (event, userLocation) => {
          if (!userLocation || !event.latitude || !event.longitude) return 0;
          const distance = calculateDistance(
            userLocation.latitude,
            userLocation.longitude,
            event.latitude,
            event.longitude
          );
          return Math.max(0, 1 - distance / 10000); // Score inversement proportionnel à la distance
        }
      },
      {
        name: 'time',
        weight: 0.4,
        calculate: (event) => {
          if (!event.datetime) return 0;
          const now = new Date();
          const eventDate = new Date(event.datetime);
          const daysUntil = (eventDate.getTime() - now.getTime()) / (1000 * 3600 * 24);
          return Math.max(0, 1 - daysUntil / 10); // Priorité aux événements proches dans le temps
        }
      },
      {
        name: 'popularity',
        weight: 0.2,
        calculate: (event) => {
          return Math.min(1, (event.views || 0) / 100); // Score basé sur les vues
        }
      }
    ],
    options: {
      maxDistance: 10000,  // km
      maxEvents: 50,    // événements
      maxDays: 30       // jours
    }
  },
  map: {
    rules: [
      {
        name: 'distance',
        weight: 0.6,
        calculate: (event, userLocation) => {
          if (!userLocation || !event.latitude || !event.longitude) return 0;
          const distance = calculateDistance(
            userLocation.latitude,
            userLocation.longitude,
            event.latitude,
            event.longitude
          );
          return Math.max(0, 1 - distance / 10000); 
        }
      },
      {
        name: 'time',
        weight: 0.4,
        calculate: (event) => {
          if (!event.datetime) return 0;
          const now = new Date();
          const eventDate = new Date(event.datetime);
          const daysUntil = (eventDate.getTime() - now.getTime()) / (1000 * 3600 * 24);
          return Math.max(0, 1 - daysUntil / 7);
        }
      }
    ],
    options: {
      maxDistance: 10000,  // km
      maxEvents: 30,    // événements
      maxDays: 30        // jours
    }
  }
};

// Fonction principale de tri
export const sortEvents = (
  events: Event[],
  viewMode: 'list' | 'map',
  userLocation?: { latitude: number; longitude: number },
  userMaxDistance?: number 
): Event[] => {
  const config = viewConfigs[viewMode];
  const effectiveMaxDistance = userMaxDistance || config.options.maxDistance;

  return events
    .filter(event => {
      if (!event.datetime || !event.latitude || !event.longitude) return false;
      
      if (userLocation) {
        const distance = calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          event.latitude,
          event.longitude
        );
        if (viewMode === 'list' && userMaxDistance && distance > userMaxDistance) {
          return false;
      }
    }

      const now = new Date();
      const eventDate = new Date(event.datetime);
      const daysUntil = (eventDate.getTime() - now.getTime()) / (1000 * 3600 * 24);
      
      return daysUntil <= config.options.maxDays;
    })
    .sort((a, b) => {
      let scoreA = 0;
      let scoreB = 0;

      config.rules.forEach(rule => {
        scoreA += rule.calculate(a, userLocation) * rule.weight;
        scoreB += rule.calculate(b, userLocation) * rule.weight;
      });

      return scoreB - scoreA;
    })
    .slice(0, config.options.maxEvents);
};