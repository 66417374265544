import React, { useState } from 'react';
import { OrganizerProfile } from '../../../types/organizer.types';
import { Plus, Calendar, Users, TrendingUp, Settings, HelpCircle, Home } from 'lucide-react';
import ProfileForm from '../ProfileForm/ProfileForm';
import { useOrganizer } from '../../../contexts/OrganizerContext';
import './OrganizerNavbar.css';

export type ActiveView = 'list' | 'create' | 'stats';

interface OrganizerNavbarProps {
  profile?: OrganizerProfile;
  onUpdateProfile: (profileData: Omit<OrganizerProfile, 'id' | 'createdAt' | 'updatedAt'>) => void;
}

const OrganizerNavbar: React.FC = () => {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const { organizerProfile } = useOrganizer();


  return (
    <>
      <nav className="organizer-navbar">
        <div className="navbar-left">
          <button className="menu-button">
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
            </svg>
          </button>
        </div>

        <div className="navbar-right">
          <button 
            className="profile-button"
            onClick={() => setIsProfileModalOpen(true)}
            title={organizerProfile?.organizer || 'Configurer le profil'}
          >
            {organizerProfile?.organizerImage ? (
              <img 
                src={organizerProfile.organizerImage} 
                alt={organizerProfile.organizer} 
                className="profile-image"
              />
            ) : (
              <div className="profile-placeholder">
                {organizerProfile?.organizer?.charAt(0)?.toUpperCase() || '?'}
              </div>
            )}
            {!organizerProfile && <span className="setup-badge">!</span>}
          </button>
        </div>
      </nav>

      {isProfileModalOpen && (
        <ProfileForm
          onClose={() => setIsProfileModalOpen(false)}
        />
      )}
    </>
  );
};

export default OrganizerNavbar;