import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateTimeFilters.css';
import { DateFilter, TimeFilter, DATE_FILTERS, TIME_FILTERS } from '../../types/event.types';
import { formatDate } from '../../utils/dateUtils';
import './DateTimeFilters.css';
import LocationFilter from './LocationFilter';

interface DateTimeFiltersProps {
  onDateFilterChange: (date: string) => void;
  onTimeFilterChange: (time: string) => void;
  onDistanceFilterChange: (distance: number) => void;
  onLocationChange: (location: { latitude: number; longitude: number }) => void;
  currentLocation?: { latitude: number; longitude: number } | null;
  isLoadingLocation: boolean;
  viewMode: 'list' | 'map';
}


const DateTimeFilters: React.FC<DateTimeFiltersProps> = ({ 
  onDateFilterChange, 
  onTimeFilterChange,
  onDistanceFilterChange,
  onLocationChange,
  currentLocation,
  isLoadingLocation,
  viewMode
}) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [customDate, setCustomDate] = useState<Date | null>(null);
  const [distance, setDistance] = useState<number>(10);
  
  
  const handleDistanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    setDistance(newValue);
    onDistanceFilterChange(newValue);
  };
  const [distanceFilter, setDistanceFilter] = useState<number>(10);

  const handleDistanceFilterChange = (distance: number) => {
    setDistanceFilter(distance);
    console.log('Distance filter changed to:', distance);
  };

  const handleDateChange = (value: string) => {
    if (value === 'custom') {
      setIsDatePickerOpen(true);
      return;
    }
    const newValue = selectedDate === value ? '' : value;
    setSelectedDate(newValue);
    onDateFilterChange(newValue);
    setCustomDate(null);
  };

  const handleCustomDateChange = (date: Date | null) => {
    if (date) {
      setCustomDate(date);
      setSelectedDate('custom');
      onDateFilterChange(`custom_${formatDate(date)}`);
      setIsDatePickerOpen(false);
    }
  };

  const handleTimeChange = (value: string) => {
    const newValue = selectedTime === value ? '' : value;
    setSelectedTime(newValue);
    onTimeFilterChange(newValue);
  };

  const handleCustomDateClick = () => {
    if ('ontouchstart' in window) {
      // Créer l'input
      const input = document.createElement('input');
      input.type = 'date';
      input.min = new Date().toISOString().split('T')[0];
      input.value = customDate ? formatDate(customDate) : '';
      
      // Styles pour rendre l'input accessible mais invisible
      input.style.position = 'absolute';
      input.style.top = '0';
      input.style.left = '0';
      input.style.opacity = '0.01';
      input.style.pointerEvents = 'none';
      
      // Gestionnaires d'événements
      const handleChange = (e: Event) => {
        const selectedDate = (e.target as HTMLInputElement).value;
        if (selectedDate) {
          handleCustomDateChange(new Date(selectedDate));
        }
        cleanup();
      };
  
      const handleCancel = () => {
        cleanup();
      };
  
      const cleanup = () => {
        input.removeEventListener('change', handleChange);
        input.removeEventListener('blur', handleCancel);
        document.body.removeChild(input);
      };
  
      input.addEventListener('change', handleChange);
      input.addEventListener('blur', handleCancel);
      
      // Ajouter et focus
      document.body.appendChild(input);
      input.focus();
      input.click();
    } else {
      setIsDatePickerOpen(true);
    }
  };

  return (
    <div className="filters-container">
      {/* Nouveau filtre de localisation */}
      <LocationFilter
        onLocationChange={onLocationChange}
        isLoading={isLoadingLocation}
        currentLocation={currentLocation}
      />
      {/* Filtres de date */}
      <div className="date-filters">
      {DATE_FILTERS.map((filter) => (
  filter.value === 'custom' ? (
    <button 
      key={filter.id}
      className={`filter-btn ${selectedDate === 'custom' ? 'active' : ''}`}
      onClick={handleCustomDateClick}
      data-custom="true"
    >
      {customDate ? formatDate(customDate) : 'Choose date'}
    </button>
  ) : (
    <button 
      key={filter.id}
      className={`filter-btn ${selectedDate === filter.value ? 'active' : ''}`}
      onClick={() => handleDateChange(filter.value)}
    >
      {filter.label}
    </button>
           )
        ))}
        {isDatePickerOpen && window.innerWidth > 768 && (
  <>
    <div 
      className="date-picker-overlay"
      onClick={() => setIsDatePickerOpen(false)}
    />
    <div className="date-picker-container">
      <button 
        className="date-picker-close"
        onClick={() => setIsDatePickerOpen(false)}
      >
        ×
      </button>
      <DatePicker
        selected={customDate}
        onChange={handleCustomDateChange}
        inline
        minDate={new Date()}
        dateFormat="yyyy-MM-dd"
        calendarClassName="custom-calendar"
        monthsShown={1}
        showPopperArrow={false}
      />
    </div>
  </>
)}
      </div>

      {/* Filtres horaires */}
      <div className="time-filters">
        {TIME_FILTERS.map((filter) => (
          <button 
            key={filter.id}
            className={`time-btn ${selectedTime === filter.value ? 'active' : ''}`}
            onClick={() => handleTimeChange(filter.value)}
          >
            {filter.icon} {filter.label}
          </button>
        ))}
      </div>
    
{viewMode === 'list' && (
  <div className="distance-filter">
    <label className="distance-label">
      <span>📍 Distance maximale : {distance} km</span>
    </label>
    <input
      type="range"
      min="1"
      max="50"
      value={distance}
      onChange={handleDistanceChange}
      className="distance-slider"
    />
  </div>
)}
</div>
);
};

export default DateTimeFilters;