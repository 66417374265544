import React, { useEffect } from 'react';
import { Clock, MapPin, Target, X } from 'lucide-react';
import DateTimeFilters from './DateTimeFilters';
import SearchBar from '../SearchBar/SearchBar';
import TagList from '../SearchBar/TagList';
import { EVENT_TAGS } from '../../types/event.types';
import './FilterPanel.css';

interface FilterPanelProps {
  isOpen: boolean;
  onClose: () => void;
  onDateFilterChange: (date: string) => void;
  onTimeFilterChange: (time: string) => void;
  onDistanceFilterChange: (distance: number) => void;
  onLocationChange: (location: { latitude: number; longitude: number }) => void;
  currentLocation?: { latitude: number; longitude: number } | null;
  isLoadingLocation: boolean;
  viewMode: 'list' | 'map';
  // Nouvelles props pour SearchBar et TagList
  searchText: string;
  setSearchText: (text: string) => void;
  searchCity: string;
  setSearchCity: (city: string) => void;
  selectedTags: string[];
  onTagSelect: (tagId: string) => void;
  customDate: string;
  onCustomDateChange: (date: string) => void;
}

const FilterPanel: React.FC<FilterPanelProps> = ({
  isOpen,
  onClose,
  searchText,
  setSearchText,
  searchCity,
  setSearchCity,
  selectedTags,
  onTagSelect,
  ...filterProps
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div 
          className={`filter-panel-overlay ${isOpen ? 'visible' : ''}`}
          onClick={onClose}
        />
      )}
      <div className={`filter-panel ${isOpen ? 'open' : ''}`}>
        <div className="filter-panel-header">
          <button className="close-button" onClick={onClose}>
            <X size={20} />
          </button>
        </div>

 {/* WHERE to do */}
 <div className="filter-section">
          <h3 className="section-title">
            <MapPin size={20} />
            Where to do?
          </h3>
        </div>
       
          {/* WHEN to do */}
          <div className="filter-section">
          <h3 className="section-title">
            <Clock size={20} />
            When to do?
          </h3>
          <DateTimeFilters {...filterProps} />
        </div>
       
        {/* WHAT to do */}
        <div className="filter-section">
          <h3 className="section-title">
            <Target size={20} />
            What to do?
          </h3>
          <SearchBar 
            isExpanded={true}
            setIsExpanded={() => {}}
            searchText={searchText}
            setSearchText={setSearchText}
            searchCity={searchCity}
            setSearchCity={setSearchCity}
          />
          <TagList 
            tags={EVENT_TAGS}
            selectedTags={selectedTags}
            onTagSelect={onTagSelect}
          />
        </div>

     

       
      </div>
    </>
);
}
export default FilterPanel;