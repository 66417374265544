import React, { useState, useRef, useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import './LocationFilter.css';

interface LocationFilterProps {
  onLocationChange: (location: { latitude: number; longitude: number }) => void;
  isLoading: boolean;
  currentLocation?: { latitude: number; longitude: number } | null;
}

const LocationFilter: React.FC<LocationFilterProps> = ({
  onLocationChange,
  isLoading,
  currentLocation
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [isSearchMode, setIsSearchMode] = useState(false);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const geocoderRef = useRef<google.maps.Geocoder | null>(null);
  
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries: ['places']
  });

  // Initialiser le geocoder
  useEffect(() => {
    if (isLoaded && !geocoderRef.current) {
      geocoderRef.current = new google.maps.Geocoder();
    }
  }, [isLoaded]);

  // Mettre à jour l'adresse quand la localisation change
  useEffect(() => {
    if (isLoaded && currentLocation && geocoderRef.current) {
      const { latitude, longitude } = currentLocation;
      geocoderRef.current.geocode({
        location: { lat: latitude, lng: longitude }
      }, (results, status) => {
        if (status === 'OK' && results?.[0]) {
          console.log('Geocoding results:', results[0].address_components); // Pour debug
  
          // Chercher toutes les composantes possibles
          const components = results[0].address_components;
          let locationDetails = {
            neighborhood: components.find(c => 
              c.types.includes('neighborhood') || 
              c.types.includes('sublocality_level_1')
            ),
            district: components.find(c => 
              c.types.includes('sublocality') || 
              c.types.includes('administrative_area_level_3')
            ),
            city: components.find(c => 
              c.types.includes('locality') || 
              c.types.includes('postal_town')
            ),
            arrondissement: components.find(c =>
              c.long_name.includes('Arrondissement')
            )
          };
  
          // Construire le nom de la localisation
          let locationName;
          if (locationDetails.neighborhood) {
            // Pour les quartiers : "Vieux-Port, Marseille"
            locationName = `${locationDetails.neighborhood.long_name}${locationDetails.city ? `, ${locationDetails.city.short_name}` : ''}`;
          } else if (locationDetails.district) {
            // Pour les districts/zones : "La Plaine, Marseille"
            locationName = `${locationDetails.district.long_name}${locationDetails.city ? `, ${locationDetails.city.short_name}` : ''}`;
          } else if (locationDetails.arrondissement) {
            // Pour Paris : "11e Arrondissement"
            locationName = locationDetails.arrondissement.short_name;
          } else if (locationDetails.city) {
            // Juste la ville si rien d'autre n'est disponible
            locationName = locationDetails.city.long_name;
          } else {
            locationName = 'Position actuelle';
          }
  
          setSearchInput(locationName);
        } else {
          setSearchInput('Position actuelle');
        }
      });
    }
  }, [currentLocation, isLoaded]);
  
  // Même logique pour handlePlaceSelect
  const handlePlaceSelect = () => {
    const place = autocompleteRef.current?.getPlace();
    
    if (place && place.geometry?.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      onLocationChange({ latitude: lat, longitude: lng });
  
      const components = place.address_components || [];
      let locationDetails = {
        neighborhood: components.find(c => 
          c.types.includes('neighborhood') || 
          c.types.includes('sublocality_level_1')
        ),
        district: components.find(c => 
          c.types.includes('sublocality') || 
          c.types.includes('administrative_area_level_3')
        ),
        city: components.find(c => 
          c.types.includes('locality') || 
          c.types.includes('postal_town')
        ),
        arrondissement: components.find(c =>
          c.long_name.includes('Arrondissement')
        )
      };
  
      let locationName;
      if (locationDetails.neighborhood) {
        // Pour les quartiers : "Vieux-Port, Marseille"
        locationName = `${locationDetails.neighborhood.long_name}${locationDetails.city ? `, ${locationDetails.city.short_name}` : ''}`;
      } else if (locationDetails.district) {
        // Pour les districts/zones : "La Plaine, Marseille"
        locationName = `${locationDetails.district.long_name}${locationDetails.city ? `, ${locationDetails.city.short_name}` : ''}`;
      } else if (locationDetails.arrondissement) {
        // Pour Paris : "11e Arrondissement"
        locationName = locationDetails.arrondissement.short_name;
      } else if (locationDetails.city) {
        // Juste la ville si rien d'autre n'est disponible
        locationName = locationDetails.city.long_name;
      } else {
        locationName = 'Position actuelle';
      }
  
      setSearchInput(locationName);
      setIsSearchMode(false);
    }
  };

  const toggleSearchMode = () => {
    setIsSearchMode(!isSearchMode);
  };

  return (
    <div className="location-filter">
      {!isSearchMode ? (
        <button 
          className="location-tag"
          onClick={toggleSearchMode}
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader2 className="animate-spin" size={16} />
          ) : (
            <>📍 {searchInput || 'Choisir un lieu'}</>
          )}
        </button>
      ) : (
        <div className="location-inputs">
          {isLoaded ? (
            <Autocomplete
              onLoad={(autocomplete) => {
                autocompleteRef.current = autocomplete;
                autocomplete.setFields(['geometry', 'formatted_address']);
              }}
              onPlaceChanged={handlePlaceSelect}
            >
              <input
                type="text"
                placeholder="Rechercher un lieu..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="location-input"
                autoFocus
              />
            </Autocomplete>
          ) : (
            <input
              type="text"
              placeholder="Chargement..."
              disabled
              className="location-input"
            />
          )}
          <button 
            className="cancel-search"
            onClick={() => setIsSearchMode(false)}
          >
            ✕
          </button>
        </div>
      )}
    </div>
  );
};

export default LocationFilter;