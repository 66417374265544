import React, { useState, useRef } from 'react';
import { OrganizerProfile, OrganizerProfileFormData } from '../../../types/organizer.types';
import { X, Upload, MapPin, Mail, Calendar, Camera, Edit, Smartphone, LogOut, AlertCircle } from 'lucide-react';
import './ProfileForm.css';
import { useOrganizer } from '../../../contexts/OrganizerContext'; 
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { toast } from 'react-hot-toast'; 
import { Autocomplete } from '@react-google-maps/api';
import { useLoadScript } from '@react-google-maps/api';


interface ProfileFormProps {
  onClose: () => void;
}

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/webp'];

const ProfileForm: React.FC<ProfileFormProps> = ({ onClose }) => {
  const { organizerProfile, updateProfile, loading } = useOrganizer(); // Nouveau hook
  const [isEditing, setIsEditing] = useState(!organizerProfile?.organizer);
  const [error, setError] = useState<string>('');
  const navigate = useNavigate(); 
  const { logout } = useAuth();
  const organizerInputRef = useRef<HTMLInputElement>(null);
  const [formData, setFormData] = useState<OrganizerProfileFormData>({
    organizer: organizerProfile?.organizer || '',
    subtitle: organizerProfile?.subtitle || '',
    organizerImage: organizerProfile?.organizerImage || '',
    organizerContact: organizerProfile?.organizerContact || '',
    location: organizerProfile?.location || '',
    latitude: organizerProfile?.latitude || 0,
    longitude: organizerProfile?.longitude || 0,
    stats: organizerProfile?.stats || {
      totalEvents: 0,
      totalViews: 0,
      activeEvents: 0
    }
  });

  const [imagePreview, setImagePreview] = useState<string>(organizerProfile?.organizerImage || '');
  const [uploadError, setUploadError] = useState<string>('');
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: ['places']
  });
  
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  const onPlaceSelected = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry?.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        
        // Chercher toutes les composantes possibles
        const components = place.address_components || [];
        let locationDetails = {
          neighborhood: components.find(c => 
            c.types.includes('neighborhood') || 
            c.types.includes('sublocality_level_1')
          ),
          district: components.find(c => 
            c.types.includes('sublocality') || 
            c.types.includes('administrative_area_level_3')
          ),
          city: components.find(c => 
            c.types.includes('locality') || 
            c.types.includes('postal_town')
          ),
          arrondissement: components.find(c =>
            c.long_name.includes('Arrondissement')
          )
        };
  
        // Construire le nom de la localisation
        let locationName: string = '';
        
        if (locationDetails.neighborhood) {
          // Pour les quartiers : "Vieux-Port, Marseille"
          locationName = `${locationDetails.neighborhood.long_name}${locationDetails.city ? `, ${locationDetails.city.short_name}` : ''}`;
        } else if (locationDetails.district) {
          // Pour les districts/zones : "La Plaine, Marseille"
          locationName = `${locationDetails.district.long_name}${locationDetails.city ? `, ${locationDetails.city.short_name}` : ''}`;
        } else if (locationDetails.arrondissement) {
          // Pour Paris : "11e Arrondissement"
          locationName = locationDetails.arrondissement.short_name;
        } else if (locationDetails.city) {
          // Juste la ville si rien d'autre n'est disponible
          locationName = locationDetails.city.long_name;
        } else {
          locationName = place.formatted_address || '';
        }
  
        setFormData(prev => ({
          ...prev,
          location: locationName,
          latitude: lat,
          longitude: lng
        }));
      }
    }
  };

  const handleClose = () => {
    // On vérifie si le profil n'a jamais été sauvegardé avec un nom
    if (!organizerProfile?.organizer) {
      setError('Veuillez sauvegarder votre profil avec un nom d\'organisation');
      organizerInputRef.current?.focus();
      return;
    }
    
    setError('');
    onClose();
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setUploadError('');
    setIsUploading(true);

    if (file.size > MAX_FILE_SIZE) {
      setUploadError('L\'image ne doit pas dépasser 2MB');
      setIsUploading(false);
      return;
    }

    if (!ACCEPTED_IMAGE_TYPES.includes(file.type)) {
      setUploadError('Format accepté : JPG, PNG ou WebP');
      setIsUploading(false);
      return;
    }

    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setImagePreview(base64String);
        setFormData(prev => ({ ...prev, organizerImage: base64String }));
        setIsUploading(false);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      setUploadError('Erreur lors du chargement de l\'image');
      setIsUploading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.organizer?.trim()) {
      setError('Veuillez renseigner le nom de votre organisation');
      organizerInputRef.current?.focus();
      return;
    }
  
    // La localisation n'est plus requise pour la sauvegarde
    try {
      await updateProfile(formData);
      setIsEditing(false);
      toast.success('Profil mis à jour avec succès !');
    } catch (error) {
      console.error('Erreur lors de la mise à jour:', error);
      toast.error('Erreur lors de la mise à jour du profil');
    }
  };

  if (loading) {
    return <div>Chargement...</div>;
  }

  const handleLogout = async () => {
    try {
      await logout();
      toast.success('Déconnexion réussie');
      navigate('/login');
    } catch (error) {
      toast.error('Erreur lors de la déconnexion');
    }
  };

  const validateContact = (contact: string): boolean => {
    if (!contact) return true;
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[\d\s-]{10,}$/;
    const instaRegex = /^@[a-zA-Z0-9._]{1,30}$/;
    const whatsappRegex = /^(wa\.me\/|whatsapp:\/\/)?(\+[\d\s-]{8,})$/;
    
    return emailRegex.test(contact) || 
           phoneRegex.test(contact) || 
           instaRegex.test(contact) || 
           whatsappRegex.test(contact);
  };

  return (
    <div 
      className="profile-form-overlay" 
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          handleClose();
        }
      }}
    >
      <div className="profile-card">
        <button className="close-button" onClick={handleClose}>
          <X size={24} />
        </button>
  
        {!isEditing ? (
          <>
            <div className="profile-header">
              <div className="profile-image-container">
                {imagePreview ? (
                  <img src={imagePreview} alt="Profile" className="profile-image" />
                ) : (
                  <div className="profile-image-placeholder">
                    {formData.organizer.charAt(0).toUpperCase()}
                  </div>
                )}
                <label htmlFor="profile-image" className="camera-button">
                  <Camera size={16} />
                </label>
                <input
                  type="file"
                  id="profile-image"
                  ref={fileInputRef}
                  accept={ACCEPTED_IMAGE_TYPES.join(',')}
                  onChange={handleImageChange}
                  className="hidden-input"
                />
              </div>
  
              <div className="profile-title">
                <h2>{formData.organizer || 'Nom de l\'organisateur'}</h2>
                <p className="profile-subtitle">
    {formData.subtitle}
    </p>
              </div>
            </div>
  
            <div className="profile-info">
              <div className="info-item">
                <Smartphone className="info-icon" />
                <span>{formData.organizerContact || 'Non renseigné'}</span>
              </div>
              <div className="info-item">
                <MapPin className="info-icon" />
                <span>{formData.location || 'Non renseigné'}</span>
              </div>
              <div className="info-item">
                <Calendar className="info-icon" />
                <span>Membre depuis {new Date().toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })}</span>
              </div>
            </div>
            <button className="edit-profile-button" onClick={() => setIsEditing(true)}>
              <Edit size={18} />
              Modifier le profil
            </button>
            <button className="logout-button" onClick={handleLogout}>
              <LogOut size={18} />
              Déconnexion
            </button>
          </>
        ) : (
          <form onSubmit={handleSubmit} className="edit-form">
            {(!formData.organizer || !formData.location) && (
              <div className="welcome-message">
                <h3>Personnalisez votre profil ! ✨</h3>
                <p>Ces informations permettront aux participants de mieux vous connaître.</p>
              </div>
            )}

            {error && (
  <div className="error-banner">
    <AlertCircle size={16} />
    {error}
  </div>
)}
  
  <div className="form-image-preview">
  <div className="profile-image-container">
    {imagePreview ? (
      <img src={imagePreview} alt="Profile" className="preview-image" />
    ) : (
      <div className="preview-placeholder">
        {formData.organizer.charAt(0).toUpperCase()}
      </div>
    )}
    <label htmlFor="profile-image" className="camera-button">
      <Camera size={16} />
    </label>
    <input
      type="file"
      id="profile-image"
      ref={fileInputRef}
      accept={ACCEPTED_IMAGE_TYPES.join(',')}
      onChange={handleImageChange}
      className="hidden-input"
    />
  </div>
</div>
  
            <div className="form-group">
              <label htmlFor="organizer">
                <Edit size={16} className="icon" />
                Nom de l'organisateur *
              </label>
              <input
                ref={organizerInputRef}
                type="text"
                id="organizer"
                value={formData.organizer}
                onChange={e => setFormData(prev => ({ ...prev, organizer: e.target.value }))}
                placeholder="Votre nom ou celui de votre organisation"
                required
              />
              {error && <span className="error-message">{error}</span>}
            </div>

            <div className="form-group">
  <label htmlFor="subtitle">
    <Edit size={16} className="icon" />
    Description
  </label>
  <input
    type="text"
    id="subtitle"
    value={formData.subtitle}
    onChange={e => setFormData(prev => ({ ...prev, subtitle: e.target.value }))}
    placeholder="Décrivez votre activité en quelques mots"
              />
            </div>
  
            <div className="form-group">
            <label htmlFor="contact">
    <Smartphone size={16} className="icon" />
    Contact *(Instagram, WhatsApp, Email or Phone)
  </label>
  <input
    type="text"
    id="contact"
    value={formData.organizerContact}
    onChange={e => {
      const value = e.target.value;
      setFormData(prev => ({ ...prev, organizerContact: value }));
      if (value && !validateContact(value)) {
        setUploadError('Format invalide. Exemples : email@domaine.com, +33612345678, @instagram, wa.me/+33612345678');
      } else {
        setUploadError('');
      }
    }}
    placeholder="..."
    required
  />
  {uploadError && <span className="error-message">{uploadError}</span>}
</div>
  
            <div className="form-group">
  <label htmlFor="location">
    <MapPin size={16} className="icon" />
    Localisation *
  </label>
  {isLoaded ? (
    <Autocomplete
      onLoad={autocomplete => setAutocomplete(autocomplete)}
      onPlaceChanged={onPlaceSelected}
    >
      <input
        type="text"
        id="location"
        value={formData.location}
        onChange={e => setFormData(prev => ({ ...prev, location: e.target.value }))}
        placeholder="Rechercher un lieu..."
      />
    </Autocomplete>
  ) : (
    <input
      type="text"
      disabled
      placeholder="Chargement..."
    />
  )}
</div>
  
            <div className="form-actions">
              <button type="button" className="cancel-button" onClick={() => {
                if (organizerProfile?.organizer) {
                  setIsEditing(false);
                } else {
                  handleClose();
                }
              }}
            >
              Annuler
              </button>
              <button type="submit" className="save-button">
                Enregistrer
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ProfileForm;