import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './NavMenu.css';

interface NavMenuProps {
  isOpen: boolean;
  onToggle: () => void;
}

const NavMenu: React.FC<NavMenuProps> = ({ isOpen, onToggle }) => {

  const menuRef = useRef<HTMLDivElement>(null);

  // Gestion du clic en dehors du menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onToggle();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, onToggle]); // Ajouter les dépendances

  const handleMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div className="nav-menu" ref={menuRef} onClick={handleMenuClick}>
      <button 
        className={`hamburger ${isOpen ? 'open' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          onToggle();
        }}
        aria-label="Menu"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      
      <div 
        className={`menu-overlay ${isOpen ? 'open' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          onToggle();
        }}
      />
      <div 
        className={`menu-content ${isOpen ? 'open' : ''}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button 
          className="close-button"
          onClick={onToggle}
          aria-label="Fermer le menu"
        >
          ✕
        </button>
        
        <Link to="/" className="menu-item" onClick={onToggle}>
          🏠 Accueil
        </Link>
        <Link to="/organizer" className="menu-item" onClick={onToggle}>
          🎯 Espace Organisateur
        </Link>
      </div>
    </div>
  );
};

export default NavMenu;