import React, { useState, useEffect, useRef } from 'react';
import OrganizerNavbar from '../../components/organizer/OrganizerNavbar/OrganizerNavbar';
import { useAuth } from '../../contexts/AuthContext';
import { Plus, Calendar, Users, TrendingUp, Settings, HelpCircle, Home } from 'lucide-react';
import { Event } from '../../types/event.types';
import EventList from '../../components/organizer/EventList/EventList';
import EventFormContainer from '../../components/organizer/EventForm/EventFormContainer';
import OrganizerStat from '../../components/organizer/OrganizerStat/OrganizerStat';
import './OrganizerPage.css';
import { useOrganizer } from '../../contexts/OrganizerContext'; // Nouveau import
import { useOrganizerEvents } from '../../hooks/useEvents';
import { eventService } from '../../firebase/event.service';
import { toast } from 'react-hot-toast';
import ProfileForm from '../../components/organizer/ProfileForm/ProfileForm';


type ActiveView = 'list' | 'create' | 'stats';
type ActiveTab = 'active' | 'draft' | 'past';

// Définir StatsCard en dehors du composant principal
interface StatsCardProps {
  title: string;
  value: number;
  icon: React.ReactNode;
  variant: 'green' | 'blue' | 'purple';
}

const StatsCard: React.FC<StatsCardProps> = ({ title, value, icon, variant }) => (
  <div className="stat-card">
    <div className="stat-content">
      <div className="stat-info">
        <p className="stat-label">{title}</p>
        <p className="stat-value">{value}</p>
      </div>
      <div className={`stat-icon-container ${variant}`}>
        {icon}
      </div>
    </div>
  </div>
);

//Test // 

const OrganizerPage: React.FC = () => {
  const { user } = useAuth();
  const { organizerProfile, loading: profileLoading } = useOrganizer();
  const [activeView, setActiveView] = useState<ActiveView>('list');
  const [activeTab, setActiveTab] = useState<ActiveTab>('active');
  const [showHelp, setShowHelp] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>();
  const [showProfileForm, setShowProfileForm] = useState(false);
  const hasShownNotifications = useRef(false);

  const { activeEvents, pastEvents, draftEvents, loading: eventsLoading, error: eventsError } 
  = useOrganizerEvents(user?.uid || '');
  const tabsRef = useRef<(HTMLButtonElement | null)[]>([]);
  const sliderRef = useRef<HTMLDivElement>(null);

  const openModal = () => {
    setActiveView('create'); 
    window.history.pushState({ modal: true }, '');
  };
  
  const closeModal = () => {
    setActiveView('list'); 
    setSelectedEvent(undefined);
  };


  const handleEventSubmit = async (eventData: Omit<Event, 'id'> & { id?: string }) => {
    try {
      if (eventData.id) {
        // Mise à jour d'un événement existant
        const { id, ...updateData } = eventData;
        await eventService.updateEvent(id, updateData);
        console.log('Événement mis à jour avec succès !');
      } else {
        // Création d'un nouvel événement
        const newEventData = {
          ...eventData,
          organizerId: user?.uid || '',
          views: 0
        };
        await eventService.createEvent(newEventData);
        console.log('Événement créé avec succès !');
      }
      
      closeModal();
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
    }
  };


  const updateSliderPosition = () => {
    const activeTabElement = tabsRef.current[
      activeTab === 'active' ? 0 : activeTab === 'draft' ? 1 : 2
    ];
    const slider = sliderRef.current;

    if (activeTabElement && slider) {
      slider.style.width = `${activeTabElement.offsetWidth}px`;
      slider.style.transform = `translateX(${activeTabElement.offsetLeft}px)`;
    }
  };

  const getFilteredEvents = () => {
    switch (activeTab) {
      case 'active':
        return activeEvents;
      case 'draft':
        return draftEvents;
      case 'past':
        return pastEvents;
      default:
        return [];
    }
  };

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      if (activeView === 'create') {
        closeModal();
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [activeView]);

  useEffect(() => {
    updateSliderPosition();
  }, [activeTab]);

  useEffect(() => {
    if (!profileLoading && user && organizerProfile && !organizerProfile.organizer && !hasShownNotifications.current) {
      setShowProfileForm(true);
      hasShownNotifications.current = true;
      
      toast.success(
        'Bienvenue ! Commençons par personnaliser votre profil ✨',
        { duration: 5000, id: 'welcome-toast' }
      );
      
      setTimeout(() => {
        toast(
          "Conseil : Un profil complet augmente votre visibilité ! ⭐",
          { duration: 4000, id: 'tip-toast' }
        );
      }, 1000);
    }
  }, [user, organizerProfile, profileLoading]);
  
  const stats = {
    activeEvents: activeEvents.length,
    totalViews: [...activeEvents, ...pastEvents, ...draftEvents]
      .reduce((sum, event) => sum + (event.views || 0), 0),
    totalAttendees: [...activeEvents, ...pastEvents, ...draftEvents].length * 10,
  };

  if (eventsLoading || profileLoading) {
    return (
      <div className="organizer-page">
        <OrganizerNavbar />
        <main className="organizer-main">
          <div className="loading-container">
            <p>Chargement...</p>
          </div>
        </main>
      </div>
    );
  }

  if (eventsError) {
    return (
      <div className="organizer-page">
        <OrganizerNavbar />
        <main className="organizer-main">
          <div className="error-container">
            <p>Erreur : {eventsError}</p>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="organizer-page">
      <OrganizerNavbar />
      <main className="organizer-content">
        {showHelp && (
          <div className="help-section">
            <div className="help-content">
              <HelpCircle className="help-icon" />
              <div>
                <h3>Need help getting started?</h3>
                <ul>
                  <li>Click the "Create Event" button to add a new event</li>
                  <li>Use tabs to switch between active, draft, and past events</li>
                  <li>Click on any event to edit its details</li>
                  <li>View event statistics to track performance</li>
                </ul>
                <button onClick={() => setShowHelp(false)}>Got it, thanks!</button>
              </div>
            </div>
          </div>
        )}

        <div className="stats-grid">
          <StatsCard 
            title="Active Events" 
            value={stats.activeEvents} 
            icon={<Calendar className="icon" />} 
            variant="green"
          />
          <StatsCard 
            title="Total Views" 
            value={stats.totalViews} 
            icon={<TrendingUp className="icon" />} 
            variant="blue"
          />
          <StatsCard 
            title="Total Attendees" 
            value={stats.totalAttendees} 
            icon={<Users className="icon" />} 
            variant="purple"
          />
        </div>

        <button 
        className="create-event-button"
        onClick={() => {
          setSelectedEvent(undefined);
          openModal(); // Utilisez openModal au lieu de setActiveView
        }}
      >
        <Plus className="icon" />
        <span>Create Event</span>
      </button>

        {/* Contenu principal */}
       
        {activeView === 'list' && (
  <>
      <div className="tabs-section">
      <div className="tabs-container">
      <button
  ref={el => tabsRef.current[0] = el}
  className={`tab ${activeTab === 'active' ? 'active' : ''}`}
  onClick={() => setActiveTab('active')}
>
  Upcoming Events
</button>
<button
  ref={el => tabsRef.current[1] = el}
  className={`tab ${activeTab === 'draft' ? 'active' : ''}`}
  onClick={() => setActiveTab('draft')}
>
  Drafts 
</button>
<button
  ref={el => tabsRef.current[2] = el}
  className={`tab ${activeTab === 'past' ? 'active' : ''}`}
  onClick={() => setActiveTab('past')}
>
  Past Events
</button>
        <div ref={sliderRef} className="sliding-bar"></div>
      </div>
    </div>

    <EventList 
  events={getFilteredEvents()}
  onEventSelect={(event) => {
    setSelectedEvent(event);
    openModal();
  }}
/>
          </>
        )}

{activeView === 'create' && (
      <EventFormContainer 
        event={selectedEvent}
        organizerId={user?.uid || ''}
        onSubmit={handleEventSubmit}
        onClose={closeModal}
        />
      )}

        {activeView === 'stats' && (
         <OrganizerStat events={[...activeEvents, ...pastEvents, ...draftEvents]} />
        )}
      </main>
      {showProfileForm && (
        <ProfileForm onClose={() => setShowProfileForm(false)} />
      )}
    </div>
  );
};

export default OrganizerPage;