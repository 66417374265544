const UPLOAD_URL = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`;

export const cloudinaryService = {
  async uploadImage(file: File): Promise<string> {
    console.log('Début de l\'upload vers Cloudinary:', file.name);
    
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET!);

    try {
      const response = await fetch(UPLOAD_URL, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        console.error('Réponse Cloudinary non-OK:', response.status, response.statusText);
        throw new Error('Upload failed');
      }

      const data = await response.json();
      console.log('Réponse Cloudinary:', data);
      
      if (!data.secure_url) {
        console.error('Pas d\'URL sécurisée dans la réponse Cloudinary');
        throw new Error('No secure URL in response');
      }

      console.log('URL sécurisée Cloudinary:', data.secure_url);
      return data.secure_url;
    } catch (error) {
      console.error('Erreur lors de l\'upload vers Cloudinary:', error);
      throw error;
    }
  }
};