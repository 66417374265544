import { LatLngBounds, LatLng } from 'leaflet';
import { Event } from '../types/event.types';

export const calculateMapBounds = (events: Event[], userLocation?: { latitude: number; longitude: number }) => {
  if (events.length === 0 && !userLocation) {
    // Retourner les coordonnées par défaut de la France
    return {
      center: [46.227638, 2.213749] as [number, number],
      zoom: 6
    };
  }

  const bounds = new LatLngBounds([]);

  // Ajouter les événements aux bounds
  events.forEach(event => {
    if (event.latitude && event.longitude) {
      bounds.extend(new LatLng(event.latitude, event.longitude));
    }
  });

  // Ajouter la position de l'utilisateur si disponible
  if (userLocation?.latitude && userLocation?.longitude) {
    bounds.extend(new LatLng(userLocation.latitude, userLocation.longitude));
  }

  // Calculer le zoom optimal
  const zoom = events.length === 1 ? 13 : // Un seul événement
              events.length <= 3 ? 11 :   // Quelques événements
              events.length <= 10 ? 9 :   // Plus d'événements
              7;                          // Beaucoup d'événements

  return {
    bounds,
    zoom
  };
};