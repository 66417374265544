import { useState, useCallback } from 'react';

export interface NavMenuHook {
  isOpen: boolean;
  toggleMenu: () => void;
}

export const useNavMenu = (): NavMenuHook => {
  const [isOpen, setIsOpen] = useState(false);
  
  const toggleMenu = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  return { isOpen, toggleMenu };
};