
import React from 'react';
import { EventTag } from '../../types/event.types';
import './TagList.css';

type TagListProps = {
  tags: EventTag[];
  onTagSelect: (tagId: string) => void;
  selectedTags: string[];
};

const TagList = ({ tags, onTagSelect, selectedTags }: TagListProps) => {
  return (
    <div className="tag-list">
      {tags.map((tag) => (
        <button
          key={tag.id}
          className={`tag ${selectedTags.includes(tag.id) ? 'selected' : ''}`}
          onClick={() => onTagSelect(tag.id)}
        >
          <span className="tag-icon">{tag.icon}</span>
          <span className="tag-label">{tag.label}</span>
        </button>
      ))}
    </div>
  );
};

export default TagList;