import { db } from './config';
import { collection, getDocs, addDoc, Timestamp, updateDoc, doc } from 'firebase/firestore';
import { Event } from '../types/event.types';

const COLLECTION_NAME = 'events';

export class EventService {
  async getAllEvents(): Promise<Event[]> {
    try {
      const eventsRef = collection(db, COLLECTION_NAME);
      const snapshot = await getDocs(eventsRef);
      return snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          organizerId: data.organizerId || '', 
          title: data.title || '',
          tags: data.tags || [],
          views: data.views || 0,
          datetime: data.datetime instanceof Timestamp ? data.datetime.toDate() : data.datetime,
          createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : data.createdAt,
          updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : data.updatedAt,
          ...data
        } as Event;
      });
    } catch (error) {
      console.error('Erreur lors de la récupération des événements:', error);
      throw error;
    }
  }

  async createEvent(eventData: Omit<Event, 'id'>): Promise<string> {
    try {
      const firestoreData = {
        ...eventData,
        views: 0,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        datetime: eventData.datetime ? Timestamp.fromDate(eventData.datetime) : null
      };
  
      const eventsRef = collection(db, COLLECTION_NAME);
      const docRef = await addDoc(eventsRef, firestoreData);
      return docRef.id;
    } catch (error) {
      console.error('Erreur lors de la création de l\'événement:', error);
      throw error;
    }
  }

  async updateEvent(eventId: string, eventData: Partial<Omit<Event, 'id'>>): Promise<void> {
    try {
      const eventRef = doc(db, COLLECTION_NAME, eventId);
      const updateData = {
        ...eventData,
        updatedAt: Timestamp.now(),
        ...(eventData.datetime && {
          datetime: eventData.datetime instanceof Date 
            ? Timestamp.fromDate(eventData.datetime)
            : eventData.datetime
        })
      };

      await updateDoc(eventRef, updateData);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'événement:', error);
      throw error;
    }
  }
}

export const eventService = new EventService();