import React from 'react';
import { Event } from '../../../types/event.types';
import './EventList.css';

interface EventListProps {
  events: Event[];
  onEventSelect: (event: Event) => void;
}

const EventList: React.FC<EventListProps> = ({ events, onEventSelect }) => {
  const formatDate = (date: Date | undefined | null): string => {
    if (!date) return 'Date non définie';
    
    try {
      const eventDate = date instanceof Date ? date : new Date(date);
      if (isNaN(eventDate.getTime())) {
        return 'Date invalide';
      }
      return eventDate.toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      return 'Date invalide';
    }
  };

  return (
    <div className="event-list-container">
      <div className="event-list">
        {events.map(event => (
          <div 
            key={event.id} 
            className="event-list-item"
            onClick={() => onEventSelect(event)}
          >
            {event.image && (
              <img 
                src={event.image} 
                alt={event.title} 
                className="event-list-image"
              />
            )}
            <div className="event-list-content">
              <h3 className="event-list-item-title">
                {event.title || 'Sans titre'}
              </h3>
              <p className="event-list-date">
              {event.status === 'draft' ? 'Brouillon' : formatDate(event.datetime)}
              </p>
              <p className="event-list-location">
                {event.location || 'Lieu non défini'}
              </p>
              <div className="event-list-stats">
                <span>👁️ {event.views || 0} vues</span>
                {event.attendees && <span>👥 {event.attendees} participants</span>}
              </div>
            </div>
            <span className={`status-tag status-${event.status}`}>
              {event.status === 'draft' ? 'Brouillon' : event.status}
            </span>
          </div>
        ))}
        {events.length === 0 && (
          <div className="no-events">
            Aucun événement dans cette catégorie
          </div>
        )}
      </div>
    </div>
  );
};

export default EventList;